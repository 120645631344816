.page-clinics-response {
	//margin: 60px 0 60px 0; 
	.page-title-container{
		margin-top: 86px;
	}
	
	.view-reviews {
		position: relative;

		.view-content {
			padding: 23px 0 0 0;
			margin-bottom: 60px;
		}

		.view-filters {
			position: absolute;
			top: -39px;
   			right: -25px;
			padding-right: 0;
			@include respond-to('tablet'){
			  margin: 18px auto 0 auto;
			  width: 250px;
			  position: relative;
			  top: 0;
			  right: 0;
			}
			@include respond-to('mobile'){
			  margin: 18px auto 0 auto;
			  width: 250px;
			  position: relative;
			  top: 0;
			  right: 0;
			}
		}	
	}
	
	.view-header{
		@include respond-to('tablet'){
			text-align: center;
		}
		@include respond-to('mobile'){
			text-align: center;
		}
		a{
			@extend .btn, .btn-default;
		    padding: 2px 71px;
		    border-radius: 60px;
		    color: #696969;
		    font-family: Verdana;
		    font-size: 11px;
		    font-weight: 700;
		    line-height: 27px;
		    text-transform: uppercase;
		    position: absolute;
		    left: 0px;
    		top: -32px;	
		    outline: 0;
		    @include respond-to('tablet'){
		    	position: relative;
		    	top: 0;
		    	margin-top: 40px;
		    }
		    @include respond-to('mobile'){
		    	position: relative;
		    	top: 0;
		    	margin-top: 40px;
		    }
			&:active, &:hover, &:focus {
			    outline: 0;
			    outline-offset: 0;
			}		
			&:hover{
				background: #da2d29;
				border: 1px solid #da2d29;
				color: white;
			}     
		}
	}

	.views-exposed-form {
		#edit-submit-reviews {
			display: none;
		}
	}

	.field-type-text-with-summary {
		color: #999;
		line-height: 27px; 
		margin: 60px 0 46px 0;
	    position: relative;
	    font-family: Verdana;
	}

	h2 {
		color: #da2d29;
		font-family: Verdana;
		font-size: 14px;
		font-weight: bold;
		margin: 0 0 60px 0; 
	}

	.views-row {
		border-bottom: 1px solid #d6d9e5;
	}	
}


@media screen and (max-width: 767px) {
	.view-reviews{
		.view-content {
			text-align: center;
		}
	}
}