.node-type-article{
	.node-article{
		padding: 90px 0 0 0;
		> .content{
			@include clearfix();
		}
	}
	.left-col{
		width: 360px;
		float: left;
		@include respond-to('tablet'){
			float: none;
			width: 300px;
			margin: 0 auto;
		}
		@include respond-to('mobile'){
			float: none;
			width: 300px;
			margin: 0 auto;
		}
	}
	.right-col{
		width: calc(100% - 360px);
		float: left;
		@include respond-to('tablet'){
			float: none;
			width: 100%;
			margin-top: 40px;
		}
		@include respond-to('mobile'){
			float: none;
			width: 100%;
			margin-top: 40px;
		}
		.title-container{
			font-family: $GothamProBlack;
			font-size: 23px;
			text-transform: uppercase;
			color: $siniy;
			@include respond-to('tablet'){
				font-size: 20px;
			}
			@include respond-to('mobile'){
				font-size: 20px;
			}
			.date{
				color: #999;
				padding-right: 8px;
			}
		}
		.field-name-body{
			color: #999;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.94;
			margin: 22px 0 0 0;
		}
	}
}

.view-v-articles{
	.view-filters {
	  margin-top: -31px;
	  padding-right: 5px;
	  @include respond-to('tablet'){
	  	margin-top: 40px;
	  	padding-right: 0px;
	  	width: 250px;
	  	margin-left: auto;
	  	margin-right: auto;
	  }
	  @include respond-to('mobile'){
	  	margin-top: 40px;
	  	padding-right: 0px;
	  	width: 250px;
	  	margin-left: auto;
	  	margin-right: auto;
	  }
	  .views-exposed-form {
	  	.views-exposed-widgets{
	  		margin: 0;
	  	}
	    .views-exposed-widget {
	      float: right;
	      padding: 0;
	      @include respond-to('tablet'){
	      	float: none;
	      }
	      @include respond-to('mobile'){
	      	float: none;
	      }
	    }
	  }
	}

	.view-content{
		display: flex;
		flex-direction:row;
		flex-wrap:wrap;
		padding: 81px 0 0 0;
		@include respond-to('tablet'){
			margin-bottom: 40px;
		}
		@include respond-to('mobile'){
			margin-bottom: 60px;
		}
		.views-row{
			width: 50%;
			position: relative;
			min-height: 344px;
			@include respond-to('desktop'){
				margin-bottom: 104px;
				padding: 0 93px 0 56px;
				&:nth-child(2n+1){
					&:after{
						content: '';
						position: absolute;
						top: 8px;
						right: 1px;
						width: 1px;
						height: 95%;
						background-color: rgba(50,66,127,.3);
					}
				}
				&:nth-child(2n+2){
					padding: 0 49px 0 107px;
				}
			}
			@include respond-to('tablet'){
				text-align: center;
				border-bottom: 1px solid rgba(50,66,127,.3);
				margin-bottom: 50px;
				padding-bottom: 50px;
			}
			@include respond-to('mobile'){
				width: 100%;
				text-align: center;
				&:not(:last-child){
					border-bottom: 1px solid rgba(50,66,127,.3);
					margin-bottom: 50px;
					padding-bottom: 50px;
				}
			}
			.node-article{
				> .content{
					@include clearfix();
				}
				.left-col{
					width: 160px;
					float: left;
					@include respond-to('tablet'){
						width: 100%;
						float: none;
					}
					@include respond-to('mobile'){
						width: 100%;
						float: none;
					}
					.field-name-field-image{
						margin: 8px 0 0 0;
						@include respond-to('tablet'){
							width: 120px;
							margin: 0 auto
						}
						@include respond-to('mobile'){
							width: 120px;
							margin: 0 auto
						}
					}
					.node-detail{
						@include respond-to('tablet'){
							display: none;
						}
						@include respond-to('mobile'){
							display: none;
						}
						a{
							display: flex;
							flex-direction:row;
							align-items:center;
							width: 120px;
							height: 52px;
							border: 1px solid rgba(50,66,127,.3);
							border-radius: 26px;
							color: $navyblue;
							font-family: Verdana;
							font-size: 16px;
							font-weight: 400;
							padding: 0 0 0 20px;
							background-image: url('../images/more-arrows-navyblue.png');
							background-repeat: no-repeat;
							background-position: center right 22px;
							margin: 24px 0 0 0;
							&:hover{
								background-color: #da2d29;
								border-color: #da2d29;
								color: white;
								background-image: url('../images/more-arrows-hover.png');
								text-decoration: none;
							}
						}
					}
				}
				.right-col{
					width: calc(100% - 160px);
					float: left;
					@include respond-to('tablet'){
						width: 100%;
						float: none;
						margin-top: 35px;
						padding: 0 15px;
					}
					@include respond-to('mobile'){
						width: 100%;
						float: none;
						margin-top: 35px;
					}
					.date{
						font-family: $GothamProBlack;
						font-size: 23px;
						text-transform: uppercase;
						color: #999;
						@include respond-to('tablet'){
							display: inline;
							padding-right: 5px;
							font-size: 20px;
						}
						@include respond-to('mobile'){
							display: inline;
							padding-right: 5px;
							font-size: 20px;
						}
					}
					.title-container{
						@include respond-to('tablet') {
							height: 51px;
							overflow: hidden;
						}
						@include respond-to('mobile'){
							display: inline;
						}
						a{
							font-family: $GothamProBlack;
							font-size: 23px;
							text-transform: uppercase;
							color: $siniy;
							@include respond-to('tablet'){
								font-size: 20px;
							}
							@include respond-to('mobile'){
								font-size: 20px;
							}
							&:hover{
								text-decoration: none;
								color: #da2d29;
								outline: 0;
							}
							&:focus{
								text-decoration: none;
								outline: 0;
							}
						}
					}
					.field-name-body{
						color: #999;
						font-family: Verdana;
						font-size: 14px;
						font-weight: 400;
						margin: 48px 0 0 0;
						line-height: 1.94;
						@include respond-to('tablet') {
							height: 135px;
							margin-top: 35px;
							overflow: hidden;
						}
						p{
							margin: 0;
						}
					}
					.node-detail{
						display: none;
						@include respond-to('tablet'){
							display: block;
							margin-top: 35px;
						}
						@include respond-to('mobile'){
							display: block;
							margin-top: 35px;
						}
						a{
							display: flex;
							flex-direction:row;
							align-items:center;
							width: 120px;
							height: 52px;
							border: 1px solid rgba(50,66,127,.3);
							border-radius: 26px;
							color: $navyblue;
							font-family: Verdana;
							font-size: 16px;
							font-weight: 400;
							padding: 0 0 0 20px;
							background-image: url('../images/more-arrows-navyblue.png');
							background-repeat: no-repeat;
							background-position: center right 22px;
							margin: 24px 0 0 0;
							&:hover{
								background-color: #da2d29;
								border-color: #da2d29;
								color: white;
								background-image: url('../images/more-arrows-hover.png');
								text-decoration: none;
							}
							@include respond-to('tablet'){
								margin: 0 auto;
							}
							@include respond-to('mobile'){
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
	}
}