#header {
  height: 180px;
  .logo {
    margin-top: 30px;
      margin-left: 3px;
      display: inline-block;
      float: left;
      #logo {
        float: none;
        width: auto;
        height: auto;
      }
      @include respond-to('tablet'){
        margin-top: 30px;
        margin-left: 54px;
        #logo{
          width: 175px;
          height: 50px;
        }
      }
      @include respond-to('mobile'){
        margin-top: 30px;
        margin-left: 44px;
        #logo{
          width: 175px;
          height: 50px;
        }
      }
  }
  .facebook_header {
    position: relative;
    top: 42px;
    left: 52px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      position: absolute;
      top: 98px;
      left: 131px; 
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .h-container{
    @include respond-to('tablet'){
      display: flex;
      flex-direction: row;
      align-items:center;
    }
    @include respond-to('mobile'){
      display: flex;
      flex-direction: row;
      align-items:center;
    }
  }
  .header-top-row{
    @include respond-to('tablet'){
      display: flex;
      flex-direction: column-reverse;
      margin-left: auto;
    }
    @include respond-to('mobile'){
      display: flex;
      flex-direction: column-reverse;
      margin-left: auto;
    }
  }
  .top_right {
    float: right;
    padding-top: 30px;
    padding-right: 4px;
    @include respond-to('tablet'){
      padding-top: 21px;
      margin-left: 22px;
      padding-right: 0px;
    }
    @include respond-to('mobile'){
      padding-top: 21px;
      margin-left: 22px;
      padding-right: 0px;
    }
    .region {
      float: left;
    }
    .four_links {
      width: 408px;
      @include respond-to('tablet'){
        display: none;
      }
      @include respond-to('mobile'){
        display: none;
      }
      .block__content {
        ul {
          text-align: right;
          li {
            display: inline-block;
            a {
              color: dimgrey;
              font-family: Verdana;
              font-size: 13px;
              font-weight: 400;
              line-height: 27px;
              opacity: 0.5;
              border: 1px solid dimgrey;
              border-radius: 16px;
              margin-left: 5px;
              text-align: center;
              padding: 0px;
              line-height: 30px;
              margin-bottom: 10px;
              &:hover, &.active {
                color: white;
                opacity: 1;
                background-color: #da2d29;
                border: 1px solid #da2d29;
              }
            }
            &:nth-child(2n) {
              a {
                width: 221px;
                
              }
            }
            &:nth-child(2) {
              a {
                opacity: 1;
                background-color: #da2d29;
                border: 1px solid #da2d29;
                color: white;
                &:hover, &.active {
                  opacity: 0.9; 
                }
              }
            }
            &:nth-child(2n - 1) {
              a {
                width: 171px;
              }
            }
          }
        }
      }
    }
    .region-phone {
      padding-right: 23px;
      text-align: right;
      @include respond-to('tablet'){
        padding-right: 0px;
      }
      @include respond-to('mobile'){
        padding-right: 0px;
      }
      .number {
        //color: $siniy;
        color: #da2d29;
        font-family: $GothamProBlack;
        font-size: 24px;
        line-height: 65px;
        text-transform: uppercase;
        line-height: 34px;
        @include respond-to('tablet'){

        }
        @include respond-to('mobile'){

        }
      }
      .number_text {
        color: #da2d29;
        font-family: Verdana;
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
        padding-right: 6px;
        padding-top: 1px;
      }
    }
    .region-work-time {
      padding-right: 10px;
        width: 167px;
        color: dimgrey;
      font-family: Verdana;
      font-size: 12px;
      padding-top: 5px;
      line-height: 15px;
      @include respond-to('tablet'){
        display: none;
      }
      @include respond-to('mobile'){
        display: none;
      }
      .block__content {
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .mobile-burger{
    display: none;
    width: 25px;
    height: 20px;
    margin-top: 92px;
    margin-left: auto;
    background: url('../images/burger.png') no-repeat;
    cursor: pointer;
    @include respond-to('tablet'){
      display: block;
    }
    @include respond-to('mobile'){
      display: block;
    }
  }
  .header-bottom-row{
    padding-top: 10px;
    @include respond-to('tablet'){
      display: none;
    }
    @include respond-to('mobile'){
      display: none;
    }
  }
  #main-menu{
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: center;
    text-align-last:center;
    > li{
      list-style: none;
      display: inline-block;
      > span{
        cursor: pointer;
      }
      > a,span{
        color: #696969;
        font-family: Verdana;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left:14px;
        padding-right:14px;
        display: block;
        &:hover,
        &:focus{
          text-decoration: none;
          outline: 0;
        }
        span.fa.fa-caret-down{
          display: none;
        }
      }
      &:not(.dropdown){
        > a{
          &:hover,
          &.active{
            color: #da2d29;
          }
        }
      }
      &.dropdown{
        > a,span{
          padding-left:24px;
          padding-right:37px;
          background-image: url('../images/dropdown-menu.png');
          background-repeat: no-repeat;
          background-position: center right 24px;
        }
        &:hover {
          > a,span {
            background-color: rgba(50,66,127,.9);
            border-radius: 16px;
            color: white;
            background-image: url('../images/dropdown-menu-hover.png');
          }
        }
      }
      &:first-child {
        > a,span { 
          background-color: rgba(50,66,127,.9);
          border-radius: 16px;
          color: white;
          background-image: url('../images/dropdown-menu-hover.png');
        }
      }
    }
  }
  ul.dropdown-menu {
    background: rgba(50,66,127,.9);
    border-radius: 16px;
    padding: 30px;
    > li {
      &.active {
        > a,span {
          background: none;
        }
      }
      > a,span {
        color: #fff;
        float: left;
        padding: 3px 0;
        text-align: left;
        &:hover {
          background: none !important;
          text-decoration: underline;
        }
      }
    }
  }
}

body.mm{
  .mobile-menu{
    right: 0;
  }
  #all-wrapper{
    right: 275px!important;
  }
}

.mobile-menu{
  display: none;
  @include respond-to('tablet'){
    display: block;
  }
  @include respond-to('mobile'){
    display: block;
  }
  width: 275px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: -275px;
  background-color: #2e385b;
  transition:all 0.3s ease-in;
  z-index: 99;
  .region-top-links{
    background-color: $navyblue;
    padding: 40px 0 0 20px;
    #block-menu-menu-small-header-menu{
      .block__content{
        ul{
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction:column;
          li{
            list-style: none;
            margin-bottom: 10px;
            a{
              display: flex;
              flex-direction:row;
              justify-content:center;
              align-items:center;
              height: 32px;
              color: white;
              font-family: Verdana;
              font-size: 13px;
              font-weight: 400;
              line-height: 1;
              border: 1px solid rgba(255,255,255,.5);
              border-radius: 16px;
              &:hover{
                background-color: #da2d29;
                border-color: #da2d29;
              }
            }
            &:nth-child(1){
              order:1;
            }
            &:nth-child(2){
              order:4;
              a {
                background-color: #da2d29;
                border-color: #da2d29; 
              }
            }
            &:nth-child(3){
              order:2;
            }
            &:nth-child(4){
              order:3;
            }
            &:nth-child(1),
            &:nth-child(3){
              a{
                width: 171px;
              }
            }
            &:nth-child(2),
            &:nth-child(4){
              a{
                width: 221px;
              }
            }
          }
        }
      }
    }
  }
  #mobile-menu .block__content>ul,
  ul#main-menu{
    margin: 0;
    padding: 14px 0 29px 22px;
    background-color: $navyblue;
    > li{
      list-style: none;
      margin-bottom: 11px;
      &:first-child{
        display: none;
      }
      &.dropdown,&.expanded{
        > a{
          > span{
            display: none;
          }
        }
        > ul{
          //display: none!important;
          background: none;
          border: none;
          box-shadow: none;
          display: block;
          float: none;
          position: initial;
          width: 100%;
          margin-left: 0px;
          li {
            margin: 7px 0;
            a {
              color: white;
              font-family: Verdana;
              font-size: 11px;
              font-weight: 400;
              line-height: 1;
              padding: 0;

              &:hover {
                background: none;
              }
              &:before {
                color: #da2d29;
                content: '•';
                display: inline;
                font-family: Verdana;
                font-size: 14px;
                font-weight: 400;
                margin: 0 5px 0 0;
              }
            }
          }
        }
      }
      > a{
        color: white;
        padding-left: 0px;
        font-family: Verdana;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        line-height: 19px;
        padding: 0px;
        background: none;
      }
    }
  }
  #block-menu-menu-cat-mob-ver{
    padding: 48px 30px 0 21px;
    .block__content{
      > ul{
        margin: 0;
        padding: 0;
        > li{
          list-style: none;
          margin-bottom: 36px;
          &:hover{
            background-color: transparent;
          }
          > a{
            font-family: Verdana;
            color: white;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            padding: 0;
            //margin: 0 0 21px 0;
            margin: 0 0 36px 0;
            &:hover{
              background-color: transparent;
            }
            >span{
              display: none;
            }
          }
          > ul{
            margin: 0;
            padding: 0;
            > li{
              list-style: none;
              margin-bottom: 21px;
              &:hover{
                background-color: transparent;
              }
              > a{
                font-family: Verdana;
                color: white;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                padding: 0;
                &:hover{
                  background-color: transparent;
                }
                &:before{
                  content: '•';
                  display: inline;
                  font-family: Verdana;
                  color: #da2d29;
                  font-size: 14px;
                  font-weight: 400;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.social_mobile {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  @media screen and (max-width: 767px) {
    display: block;
  }
  .social {
    margin: 0 10px 0px 0;
    display: inline-block;
  }
  .clear {
    clear: both;
  }
}