/*.node-type-doctor{
  .doctor{
    // background: red;
    // background: no-repeat;
    .backgroundblur{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      filter: blur(10px);
      z-index:-1;
      background: no-repeat;
      background-size: cover;
    }
    &>div{
      filter: blur(0px);
    }
  }
  .deskdoctor{
    background: #fff;
  }
  .readmore{
      padding: 10px 20px;
      width: 175px;
      border-radius: 80px;
      color: #da2d29;
      border: 1px solid #f4c0be;
      font-weight: 700;
      font-family: Verdana;
      font-size: 16px;
      line-height: 27px;
  }
  .uwbody{
    max-height: 180px;
    overflow: hidden;
    .active{
      max-height: auto;
    }
  }
}*/

.node-type-doctor{

  .blur-block{
    position: relative;
    min-height: 670px;
    .bg-blur-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 670px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../images/bg-doc-static.jpg');
      //filter: blur(10px);
      @include respond-to('tablet'){
        width: 100%;
      }
      @include respond-to('mobile'){
        width: 100%;
        height: 420px;
      }
    }
    .d-container{
      position: relative;
      z-index: 10;
      .two-col{
        display: flex;
        flex-direction:row;
        @include respond-to('tablet'){
          flex-direction:column;
        }
        @include respond-to('mobile'){
          flex-direction:column;
        }
        .tc{
          width: 50%;
          @include respond-to('tablet'){
            width: 100%;
          }
          @include respond-to('mobile'){
            width: 100%;
          }
        }
        .tc-1{
          @include respond-to('tablet'){
            text-align: center;
          }
          @include respond-to('mobile'){
            text-align: center;
          }
          .field-name-field-doctor-photo{
            margin: 97px 0 0 0;
            @include respond-to('mobile'){
              margin: 60px 0 0 0;
            }
            .field-item{
              display: inline-block;
              border-radius: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              img{
                opacity: 0;
                @include respond-to('mobile'){
                  width: 300px;
                  height: 300px;
                }
              }
            }
          }
        }
        .tc-2{
          padding: 146px 0 0 70px;
          @include respond-to('tablet'){
            padding-left: 0px;
            text-align: center;
          }
          @include respond-to('mobile'){
            padding: 121px 0 0 0px;
            text-align: center;

          }
          .node-title{
            color: $siniy;
            font-family: $GothamProBlack;
            font-size: 31px;
            text-transform: uppercase;
            line-height: 1;
            @include respond-to('mobile'){
              font-size: 25px;
              line-height: 1.25;
            }
          }
          .field-name-field-doc-purpose-doc{
            margin: 32px 0 0 0;
            color: #666;
            font-family: Verdana;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            @include respond-to('mobile'){
              margin: 28px 0 0 0;
            }
          }
          .field-name-body{
            color: #999;
            font-family: Verdana;
            font-size: 14px;
            font-weight: 400;
            margin: 31px 0 0 0;
            line-height: 1.94;
            .field-items {
              overflow: hidden;
              &.closed {
                height: 290px;
              }
            }
            @media (min-width: 1200px) {
              min-height: 380px;
              position: relative;
            }
            @include respond-to('tablet') {
              text-align: left;
            }
            @include respond-to('mobile') {
              text-align: left;
            }
            .close_doctor_text {
              margin-bottom: 20px;
            }
            .open_doctor_text,.close_doctor_text {
              display: flex;
              flex-direction:row;
              align-items:center;
              justify-content:flex-start;
              width: 201px;
              height: 52px;
              border: 1px solid rgba(218,45,41,.3);
              color: #da2d29;
              font-family: Verdana;
              font-size: 16px;
              font-weight: 400;
              background-image: url(../images/more-arrows.png);
              background-repeat: no-repeat;
              background-position: center right 30px;
              padding: 0 0 0 26px;
              border-radius: 26px;
              display: none;
              @include respond-to('tablet'){
                margin-left: auto;
                margin-right: auto;
              }
              @include respond-to('mobile'){
                margin-left: auto;
                margin-right: auto;
              }
              &:hover{
                background-color: #da2d29;
                border-color: #da2d29;
                color: white;
                background-image: url(../images/more-arrows-hover.png);
                text-decoration: none;
                outline: 0;
              }
              &:focus{
                outline: 0;
                text-decoration: none;
              }
            }
            .expanding-formatter-trigger{
              margin: 68px 0;
              display: block;
              @include respond-to('tablet') {
                text-align: center;
              }
              @include respond-to('mobile') {
                margin: 33px 0 68px 0;
                text-align: center;
              }
              a{
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:flex-start;
                width: 201px;
                height: 52px;
                border: 1px solid rgba(218,45,41,.3);
                color: #da2d29;
                font-family: Verdana;
                font-size: 16px;
                font-weight: 400;
                background-image: url(../images/more-arrows.png);
                background-repeat: no-repeat;
                background-position: center right 30px;
                padding: 0 0 0 26px;
                border-radius: 26px;
                @include respond-to('tablet'){
                  margin-left: auto;
                  margin-right: auto;
                }
                @include respond-to('mobile'){
                  margin-left: auto;
                  margin-right: auto;
                }
                &:hover{
                  background-color: #da2d29;
                  border-color: #da2d29;
                  color: white;
                  background-image: url(../images/more-arrows-hover.png);
                  text-decoration: none;
                  outline: 0;
                }
                &:focus{
                  outline: 0;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }


  .cert-reception{
    position: relative;
    min-height: 916px;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 916px;
      background-color: $navyblue;
      background-image: url('../images/bg-doc-reception.jpg');
      background-repeat: no-repeat;
      background-position: top right;
      @include respond-to('tablet'){
        width: 100%;
        top: auto;
        bottom: 0;
      }
      @include respond-to('mobile'){
        width: 100%;
        top: auto;
        bottom: 0;
        height: 988px;
      }
    }
    .cr-container{
      position: relative;
      z-index: 10;
    }
    .two-col{
      display: flex;
      flex-direction:row;
      @include respond-to('tablet'){
        flex-direction:column;
      }
      @include respond-to('mobile'){
        flex-direction:column;
      }
      .tc{
        width: 50%;
        @include respond-to('tablet'){
          width: 100%;
        }
        @include respond-to('mobile'){
          width: 100%;
        }
      }
      .tc-1{
        .field-name-field-doc-diploma-cert{
          margin: 135px 0 0 0;
          @include respond-to('tablet'){
            text-align: center;
            margin: 75px 0 0 0;
          }
          @include respond-to('mobile'){
            text-align: center;
            margin: 16px 0 0 0;
          }
          .field-label{
            color: #da2d29;
            font-family: $GothamProBlack;
            font-size: 31px;
            text-transform: uppercase;
            line-height: 1;
            max-width: 311px;
            @include respond-to('tablet'){
              margin-left: auto;
              margin-right: auto;
            }
            @include respond-to('mobile'){
              margin-left: auto;
              margin-right: auto;
              font-size: 20px;
              line-height: 1.25;
            }
          }
          .owl-carousel{
            margin: 66px 0 0 0;
            @include respond-to('mobile'){
              margin: 25px 0 0 0;
            }
            .owl-item{
              padding-bottom: 9px;
              img{
                box-shadow: 8px 8.9px 0 rgba(0, 0, 0, 0.12);
                @include respond-to('mobile'){
                  max-width: 100%;
                  height: auto;
                }
              }
            }
            .owl-controls{
              margin: 66px 0 0 0;
              text-align: left;
              @include respond-to('tablet'){
                text-align: center;
              }
              @include respond-to('mobile'){
                text-align: center;
                margin: 39px 0 0 0;
              }
              .owl-buttons{
                font-size: 0;
                .owl-prev{
                  margin: 0 10px 0 0;
                  padding: 0;
                  font-size: 0;
                  text-indent: -9999px;
                  width: 52px;
                  height: 52px;
                  border-radius: 100%;
                  background-color: $navyblue;
                  opacity: 1;
                  background-image: url('../images/more-arrows-hover.png');
                  background-repeat: no-repeat;
                  background-position: center;
                  transform:rotate(180deg);
                  &:hover{
                    background-color: #da2d29;
                  }
                }
                .owl-next{
                  margin: 0 0 0 10px;
                  padding: 0;
                  font-size: 0;
                  text-indent: -9999px;
                  width: 52px;
                  height: 52px;
                  border-radius: 100%;
                  background-color: $navyblue;
                  opacity: 1;
                  background-image: url('../images/more-arrows-hover.png');
                  background-repeat: no-repeat;
                  background-position: center;
                  &:hover{
                    background-color: #da2d29;
                  }
                }
              }
            }
          }
        }
      }
      .tc-2{
        @include respond-to('tablet'){
          margin-top: 90px;
          .webform-client-form {
              padding: 136px 132px 112px 28px;
          }
        }
        @include respond-to('mobile'){
          margin-top: 89px;
          .webform-client-form {
            padding: 86px 10px 91px 10px;
          }
        }
      }
    }
  }

}


form.webform-client-form{
  > div{
    @include clearfix();
  }
  .form-item{
    margin-bottom: 28px;
  }

  .webform-component-markup{
    font-family: $GothamProBlack;
    font-size: 31px;
    text-transform: uppercase;
    color: white;
    line-height: 1;
    margin-bottom: 45px;
    @include respond-to('mobile'){
      font-size: 25px;
      text-align: center;
    }
    span{
      color: #da2d29;
    }
    p{
      margin: 0;
    }
  }

  input[type="text"],
  input[type="email"]{
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    color: white;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #5b6899;
    box-shadow: none;
    outline: 0;
    border-radius: 0;
    padding: 0 0 0 11px;
    height: 52px;
    @include placeholder(){
      font-family: Verdana;
      font-size: 14px;
      font-weight: 400;
      color: white;
    }
  }

  input[type="text"].datepicker{
    background-image: url('../images/select-dropdown.png');
    background-position: right 10px center;
    background-repeat: no-repeat;
  }

  select{
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    color: white;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #5b6899;
    box-shadow: none;
    outline: 0;
    border-radius: 0;
    padding: 0 0 0 11px;
    height: 52px;
    background-image: url('../images/select-dropdown.png');
    background-position: right 10px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none!important;
    option{
      color: black;
    }
    &::-ms-expand {
      display: none;
    }
  }
  textarea{
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    color: white;
    resize:none;
    background-color: transparent;
    border: 1px solid #5b6899;
    box-shadow: none;
    outline: 0;
    border-radius: 0;
    padding: 13px 0 0 20px;
    height: 111px;
    @include placeholder(){
      font-family: Verdana;
      font-size: 14px;
      font-weight: 400;
      color: white;
    }
  }
  .form-actions{
    margin: 59px 0 0 35px;
    float: left;
    @include respond-to('mobile'){
      float: none;
      margin: 50px 0 0 0;
    }
    input[type="submit"]{
      color: white;
      font-family: Verdana;
      font-size: 16px;
      font-weight: 400;
      width: 175px;
      height: 52px;
      background-color: #da2d29;
      border-radius: 26px;
      border:0;
      outline: 0;
      box-shadow: none;
      text-align: left;
      padding: 0 0 0 30px;
      margin: 0 0 0 0;
      background-image: url('../images/more-arrows-hover.png');
      background-repeat: no-repeat;
      background-position: center right 30px;
      @include respond-to('mobile'){
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

}

form.webform-client-form-738{

  .webform-component--vyberite-filial{
    width: 46%;
    float: left;
    margin-bottom: 50px;
    @include respond-to('mobile'){
      width: 100%;
      float: none;
      margin-bottom: 29px;
    }
  }
  .webform-component--zhelaemaya-data-i-vremya-{
    width: 46%;
    float: right;
    margin-bottom: 50px;
    @include respond-to('mobile'){
      width: 100%;
      float: none;
    }
  }
  .webform-component--kommentariy{
    float: left;
    width: 59.6%;
    clear: both;
    textarea {
      height: 121px;
    }
    @include respond-to('mobile'){
      width: 100%;
      float: none;
    }
  }
  .webform-component--podtverzhdayu {
    float: right;
    margin: -3px 0 0 0;
    width: 180px;
    @include respond-to('mobile') {
      float: none;
      margin: 0 auto;
    }
    .form-type-checkbox {
      margin: 0;
      label {
        background: url(../images/blue_checkbox.jpg) left top 2px no-repeat;
        color: #99a4cf;
        font-family: Verdana;
        font-size: 11px;
        font-weight: 400;
        padding-left: 25px;
      }
      input[type=checkbox] {
        display: none;
      }
      input[type=checkbox]:checked + label {
        background: url(../images/blue_checkbox_checked.jpg) left top 2px no-repeat;
      }
    }
  }
  .form-actions {
    float: right;
    margin: 12px 0 0 0;
    @include respond-to('mobile') {
      float: none;
      margin: 20px 0 0 0;
      width: 100%;
    }
  }
}

form.webform-client-form-381 {
  .webform-component--podtverzhdayu {
    float: right;
    margin: 0 60px 0 0;
    width: 250px;
    @include respond-to('mobile') {
      float: none;
      margin: 0 auto 28px auto;
      width: 180px;
    }
    .form-type-checkbox {
      margin: 3px 0 0 0;
      label {
        background: url(../images/blue_checkbox.jpg) left top 2px no-repeat;
        color: #99a4cf;
        font-family: Verdana;
        font-size: 11px;
        font-weight: 400;
        padding-left: 25px;
      }
      input[type=checkbox] {
        display: none;
      }
      input[type=checkbox]:checked + label {
        background: url(../images/blue_checkbox_checked.jpg) left top 2px no-repeat;
      }
    }
  }
  .form-actions {
    margin: 0;
  }
}

form.webform-client-form-1267 {
  .webform-component--podtverzhdayu {
    float: right;
    margin: 0 60px 0 0;
    width: 250px;
    @include respond-to('mobile') {
      float: none;
      margin: 0 auto 28px auto;
      width: 180px;
    }
    .form-type-checkbox {
      margin: 3px 0 0 0;
      label {
        background: url(../images/blue_checkbox.jpg) left top 2px no-repeat;
        color: #99a4cf;
        font-family: Verdana;
        font-size: 11px;
        font-weight: 400;
        padding-left: 25px;
      }
      input[type=checkbox] {
        display: none;
      }
      input[type=checkbox]:checked + label {
        background: url(../images/blue_checkbox_checked.jpg) left top 2px no-repeat;
      }
    }
  }
  .form-actions {
    margin: 0;
  }
}





