body.page-cost,body.page-clinics-pricelist{
	.page-title-container{
		.page-title{
			padding: 0 57px;
		}
	}
}

body.page-cost,body.page-clinics-pricelist{
	.view-v-cost{
		padding-top: 84px;
		.view-filters {
			margin-bottom: 50px;
			.views-exposed-form {
				.views-exposed-widget {
					float: none;
					padding: 0;
				}
			}
		}
		.views-widget-filter-field_cost_direction_tid {
			> label {
				display: none;
			}
		}
		.bef-select-as-radios {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			> .form-item {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					@include respond-to('tablet') {
						label {
							background-position: left center;
							border-right: 1px solid #f0f0f0;
						}
						input[type=radio]:checked + label {
							background-position: left center;
							border: none;
						}
					}
				}
				&:nth-child(3) {
					@include respond-to('tablet') {
						label {
							background-position: center;
							border-left: 1px solid #f0f0f0;
							border-right: 1px solid #f0f0f0;
							.name {
								width: 100px;
							}
						}
						input[type=radio]:checked + label {
							background-position: center;
							border: none;
						}
					}
				}
				&:nth-child(4) {
					@include respond-to('tablet') {
						label {
							background-position: right center;
							border-left: 1px solid #f0f0f0;
						}
						input[type=radio]:checked + label {
							background-position: right center;
							border: none;
						}
					}
				}
				height: 104px;
				width: 377px;
				@include respond-to('tablet') {
					width: 228px;
				}
			}
			input[type=radio] {
				display: none;
			}
			label {
				background: url(../images/label_filter.png) center no-repeat;
				height: 104px;
				padding-top: 0px;
				text-align: center;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center; 
				@include respond-to('tablet') {
					padding: 0px 10px 0 10px;
					width: 228px;
				}
				@include respond-to('mobile') {
					background-size: cover!important;
					height: 83px; 
					padding-left: 0px;
				}
				.name {
					color: #da2d29;
					font-size: 18px;
					font-weight: 700;
					margin-bottom: 10px;
					width: 100%;
					@include respond-to('tablet') {
						font-size: 15px;
						line-height: 18px;
						margin: 0 auto 5px auto;
						width: 165px;
					}
					@include respond-to('mobile') {
						font-size: 15px;
						margin-bottom: 0px;
					}
				}
				.desc {
					display: none;
					color: $navyblue;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					width: 100%;
					@include respond-to('tablet') {
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
			input[type=radio]:checked + label {
				background: url(../images/label_filter_active.png) center no-repeat;
				.name {
					color: #fff;
				}
				.desc {
					color: #fff;
				}
			}
		}
		.view-content{
			.views-field-title{
				color: $siniy;
				font-family: Verdana;
				font-size: 15px;
				font-weight: 700;
				padding: 0 40px 32px 2px;
				margin: 0;
				border:0;
				background: none;
				border-radius: 0;
				border-bottom: 1px solid rgba(50,66,127,.2);
				margin-bottom: 30px;
				.ui-accordion-header-icon{
					margin: 0;
					width: 21px;
					height: 21px;
					border-radius: 100%;
					background-color: $navyblue;
					background-image: url('../images/plus.png');
					background-repeat: no-repeat;
					background-position: center;
					position: absolute;
					top: 2px;
					right: 5px;
				}
				&.ui-accordion-header-active{
					color: #da2d29;
					font-family: Verdana;
					font-size: 20px;
					font-weight: 700;
					padding: 0 40px 32px 2px;
					margin-bottom: 28px;
					.ui-accordion-header-icon{
						//top: 5px;
						background-image: url('../images/minus.jpg');
						background-color: #da2d29;
					}
				}
			}
			.views-field-field-mc-services{
				padding: 0px 5px 39px 3px;
				border:0;
				background: none;
				.field-content{
					.paragraphs-item-pb-cost{
						border-bottom:1px solid rgba(218,45,41,.2);
						padding-bottom: 13px;
						margin-bottom: 18px;
						.content{
							display: flex;
							flex-direction:row;
							.field-name-field-pbc-name-serv{
								color: dimgrey;
								font-family: Verdana;
								font-size: 14px;
								font-weight: 400;
								margin-right: 10px;
							}
							.field-name-field-pbc-price{
								color: #da2d29;
								font-family: Verdana;
								font-size: 14px;
								font-weight: 700;
								margin-left: auto;
								white-space: nowrap;
							}
						}
					}
				}
			}

		}
	}
}