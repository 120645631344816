// Mixins
// -----------------------------------------------------------------------------
@mixin placeholder() {
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}

@mixin make-container() {
  @include container-fixed();

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


@mixin respond-to($breakpoint) {
  @if $breakpoint == "mobile" {
    @media (max-width: $screen-xs-max )  {
      @content;
    }
  }

  @else if $breakpoint == "tablet" {
    @media (min-width: $screen-sm) and (max-width: $screen-md-max ) {
      @content;
    }
  }

  @else if $breakpoint == "desktop" {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
  @else if $breakpoint == "tablet_mobile" {
    @media (max-width: $screen-md-max ) {
      @content;
    }
  }
}

@include respond-to('tablet'){
}
@include respond-to('mobile'){
}