#bottom{
  min-height: 337px;
  background-color: $navyblue;

  .bottom__mm{
    border-bottom: 2px solid #6473ad;
    #main-footer-menu{
      font-size: 0;
      padding: 0;
      margin: 0;
      text-align: justify;
      text-align-last: justify;
      > li{
        display: inline-block;
        list-style: none;
        &:first-child{
          display: none;
        }
        &.dropdown {
          > a {
            background: url('../images/pti4ka.png') right 0px center no-repeat;
          }
        }
        > a{
          display: flex;
          flex-direction:row;
          align-items:center;
          justify-content:center;
          color: white;
          font-family: Verdana;
          font-size: 11px;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
          height: 68px;
          padding: 0 19px 0 0;
          &:hover{
            text-decoration: none;
          }
          > span{
            display: none;
          }
        }
        ul.dropdown-menu {
          background: rgba(50,66,127,.9);
          border-radius: 16px;
          padding: 30px;
          > li {
            &.active {
              > a,span {
                background: none;
              }
            }
            > a,span {
              color: #fff;
              float: left;
              padding: 3px 0;
              text-align: left;
              &:hover {
                background: none !important;
                text-decoration: underline;
              }
            }
          }
        }
        > ul{
          > li{
            > a{

            }
          }
        }
      }
    }
  }

  .b-container{

  }

  .bottom__wrapper{
    display: flex;
    flex-direction:row;
    margin-top: 31px;
    #bottom__logo{
      margin-right: 53px;
    }
    .facebook_header_bottom {
      position: relative;
      top: 12px;
      margin-right: 44px;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .bottom_right{
      display: flex; 
      flex-direction:row;
      .second_block {
        padding-left: 88px;
      }
      .region-work-time{
        margin-right: 29px;
        #block-block-1 {
            color: white;
            font-family: Verdana;
            font-size: 12px;
            font-weight: 400;
            padding-top: 30px;
            line-height: 1;
            text-align: left;
            strong{
              font-weight: 700;
              text-transform: uppercase;
              margin-right: 3px;
            }
            p{
              &:not(:last-child){
                margin-bottom: 3px;
              }
              &:last-child{
                margin-bottom: 0px;
              }
            }
          }
      }
      .region-phone{
        margin-right: 33px;
        #block-block-2{
          text-align: center;
          padding-top: 4px;
          .number{
            color: #da2d29;
            font-family: $GothamProBlack; 
            font-size: 24px;
            line-height: 1;
            text-transform: uppercase;
          }
          .number_text{
            color: white;
            font-family: Verdana;
            font-size: 10px;
            font-weight: 400;
            text-transform: uppercase;
            margin-top: 7px;
          }
        }
      }
      .region-top-links{
        #block-menu-menu-small-header-menu{
          width: 402px;
          ul{
            font-size: 0;
            padding: 0;
            margin: 0;
            text-align: justify;
            text-align-last: justify;
            > li{
              display: inline-block;
              list-style: none;
              margin-bottom: 10px;
              &:nth-child(1+2n){
                margin-right: 10px;
              }
              > a{
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                text-align: center;
                color: white;
                font-family: Verdana;
                font-size: 13px;
                font-weight: 400;
                width: 171px;
                height: 32px;
                border: 1px solid rgba(255,255,255,.5);
                border-radius: 16px;
                padding: 0;
                &:hover{
                  background-color: #da2d29;
                  border-color: #da2d29;
                  text-decoration: none;
                }
              }
              &:nth-child(2){
                a { 
                  background-color: #da2d29;
                  border-color: #da2d29;
                  text-decoration: none;
                  &:hover {
                    opacity:0.9;
                  }
                }
              }
              &:nth-child(2n+2){
                > a{
                  width: 221px;
                }
              }
            }
          }
        }
      }
    }
  }

  #footer-menu{
    font-size: 0;
    padding: 0;
    margin: 20px 0 0 0;
    text-align: justify;
    text-align-last: justify;
    > li{
      display: inline-block;
      list-style: none;
      > a{
        display: flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        text-align: center;
        color: white;
        font-family: Verdana;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.94;
        height: 52px;
        border-radius: 26px;
        padding: 0 51px 0 31px;
        background: #6473ad url('../images/double-arr.png') right 30px center no-repeat;
        &:hover{
          background-color: #da2d29;
          text-decoration: none;
        }
      }
    }
  }

  .copyright{
    color: white;
    font-family: Verdana;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    margin-top: 48px;
    p{
      margin: 0;
    }
  }

}