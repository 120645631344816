#modalContent{

	.jspVerticalBar{
		right: calc(100% - 1200px);
	}

	.modal-dialog{
		width: 1200px!important;
		margin-left: auto;
		margin-right: auto;
		@include respond-to('tablet'){
			width: 688px!important;
		}
		@include respond-to('mobile'){
			width: 320px!important;
		}
	}

	.modal-dialog{
		margin: 0 auto 0 auto;
	}
	.modal-content{
		border:0;
		background: none;
		border-radius: 0;
	}
	.modal-header{
		border:0;
		padding: 0;
		#modal-title{
			display: none;
		}
		button.close{
			width: 20px;
			height: 20px;
			background: url('../images/close.png') no-repeat;
			font-size: 0;
			text-indent: -9999px;
			text-shadow:none;
			float: none;
			margin: 0 0 0 0;
			position: absolute;
			top: 132px;
			left: 563px;
			z-index: 10;
			opacity: 1;
			@include respond-to('mobile'){
				top: 15px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	#modal-content{
		padding: 0;
		background: none;
	}
	.alert {
		display: none;
	}
	div[id^=webform-ajax-wrapper] {
		height: 836px;
		padding: 95px 587px 0 67px;
		background-color: $navyblue;
		background-repeat: no-repeat;
		background-image: url('../images/bg-doc-reception.jpg');
		background-position: top -38px right;
		position: relative;
		.webform-client-form {
			height: 100%;
			padding: 0;
		}
		.webform-confirmation-container {
		  left: 0;
		  max-width: 546px;
		  position: absolute;
		  text-align: center;
		  top: 50%;
		  transform: translateY(-50%);
		  width: 100%;
		}
		@include respond-to('tablet'){
			padding: 95px 20px 0px 20px;
		}
		@include respond-to('mobile'){
			padding: 30px 10px 20px 10px;
			min-height: 836px;
			height: auto;
		}
		.webform-component-markup{
			@include respond-to('mobile'){
				margin-top: 20px;
				margin-bottom: 25px;
			}
		}
	}

}