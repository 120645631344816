body.node-type-m-about{

	.page-title-container{
		.page-title{
			padding: 0 60px;
		}
	}

	.paragraphs-item-pb-text-area{
		&.use-padding{
			padding: 52px 0 45px 0;
		}
		>.content{

		}
	}

	.our-doc-insert{
		padding: 115px 0 121px 0;
		.view-content{
			padding: 52px 0 0 0;
			.views-row{
				margin-bottom: 0;
				@include respond-to('tablet'){
					width: 100%;
				}
				@include respond-to('mobile'){
					width: 100%;
				}
			}
			.node-doctor{
				margin-bottom: 0px;
				.field-name-field-doctor-photo{
					.field-item{
						background: none!important;
					}
				}
			}
		}
		.owl-carousel{
			.owl-nav,
			.owl-controls{
				margin: 0;
				.owl-prev{
					width: 14px;
					height: 25px;
					margin: 0;
					padding: 0;
					background: url('../images/left-red.png') no-repeat;
					position: absolute;
					top: 101px;
					left: 0;
					font-size: 0;
					text-indent: -9999px;
					margin: 0;
					padding: 0;
					background-color: transparent;
					opacity: 1;
					border-radius: 0;
					@include respond-to('mobile'){
						left: 9px;
					}
					&:hover{
						background-color: transparent;
					}
				}
				.owl-next{
					width: 14px;
					height: 25px;
					margin: 0;
					padding: 0;
					background: url('../images/right-red.png') no-repeat;
					position: absolute;
					top: 101px;
					right: 0;
					font-size: 0;
					text-indent: -9999px;
					margin: 0;
					padding: 0;
					background-color: transparent;
					opacity: 1;
					border-radius: 0;
					@include respond-to('mobile'){
						right: 9px;
					}
					&:hover{
						background-color: transparent;
					}
				}
			}
		}
	}
	.view-about-us-tabs {
		padding-top: 84px;
		padding-bottom: 84px;
		.view-content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			> .views-row {
				height: 104px;
				width: 377px;
				cursor: pointer;
				&:nth-child(1) {
					@include respond-to('tablet') {
						.views-field-nothing {
							background-position: left center;
							border-right: 1px solid #f0f0f0;
						}
						&.active .views-field-nothing {
							background-position: left center;
							border: none;
						}
					}
				}
				&:nth-child(2) {
					@include respond-to('tablet') {
						.views-field-nothing {
							background-position: center;
							border-left: 1px solid #f0f0f0;
							border-right: 1px solid #f0f0f0;
							.name {
								width: 100px;
							}
						}
						&.active .views-field-nothing {
							background-position: center;
							border: none;
						}
					}
				}
				&:nth-child(3) {
					@include respond-to('tablet') {
						.views-field-nothing {
							background-position: right center;
							border-left: 1px solid #f0f0f0;
						}
						&.active .views-field-nothing {
							background-position: right center;
							border: none;
						}
					}
				}
				@include respond-to('tablet') {
					width: 228px;
				}
			}
			.views-field-counter {
				display: none;
			}
			.views-field-nothing {
				background: url(../images/label_filter.png) center no-repeat;
				height: 104px;
				/*padding-top: 15px;*/
				text-align: center;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				@include respond-to('tablet') {
					padding: 0px 10px 0 10px;
				}
				@include respond-to('mobile') {
					background-size: cover!important; 
					height: 83px;
				}
				.name {
					color: #da2d29;
					font-size: 18px;
					font-weight: 700;
					margin-bottom: 10px;
					width: 100%;
					@include respond-to('tablet') {
						font-size: 15px;
						line-height: 18px;
						margin: 0 auto 5px auto;
						width: 165px;
					}
					@include respond-to('mobile') {
						font-size: 15px;
						margin-bottom: 0px;
					}
				}
				.desc {
					display: none;
					color: $navyblue;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					width: 100%;
					@include respond-to('tablet') {
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
			.views-row.active .views-field-nothing {
				background: url(../images/label_filter_active.png) center no-repeat;
				
				.name {
					color: #fff;
				}
				.desc {
					color: #fff;
				}
			}
		}
	}
	.views-field-field-about-us {
		font-family: Verdana;
		font-size: 14px;
		font-weight: 400;
		line-height: 27px;
		color: #999;
		p {
			margin-bottom: 30px;
		}
		a {
			color: $navyblue;
			text-decoration: underline;
		}
		ul{
			padding: 0;
			margin: 0;
			li{
				list-style: none;
				font-family: Verdana;
				font-size: 17px;
				font-weight: 400;
				color: #999;
				position: relative;
				padding-left: 15px;
				margin: 0 0 16px 0;
				@include respond-to('tablet'){
					text-align: left;
				}
				@include respond-to('mobile'){
					text-align: left;
				}
				&:before{
					content: '';
					position: absolute;
					top: 9px;
					left: 0;
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background-color: #da2d29;
				}
			}
		}

		ul{
			> li{
				> ul{
					padding: 16px 0 0 0;
				}
			}
		}
	}
	.view-about-us-block {
		& > .view-content {
			.views-row {
				display: none;
			}
			.views-row-1 {
				display: block;
			}
		}
	}
}

@include respond-to('tablet'){
	.node-type-m-about{
		.paragraphs-item-pb-our-adv-ins{
			margin-bottom: 10px;
		}
		.paragraphs-item-pb-double-blk{
			.field-name-field-image{
				position: absolute;
				top: -490px;
				width: 320px;
				height: 400px;
				right: calc((100% - 688px + 29px) / 2);
				img{
					width: 320px;
					height: 400px;
				}
			}
			.field-name-field-right-back{
				width: 100%;
				float: none;
			}
			.p-container{
				.paragraphs-items-field-left-side{
					position: absolute;
				    top: -375px;
				    right: calc((100% - 688px + 54px) / 2);
				    width: initial;
				    .paragraphs-item-pb-text-area{
				    	padding: 0;
				    	.title{
				    		font-size: 18px;
				    		margin: 0;
				    		text-align: center;
				    		line-height: 1.24;
				    	}
				    	.body{
				    		text-align: center;
				    		margin: 23px 0 0 0;
				    	}
				    }
				}
				.paragraphs-items-field-right-side{
					width: 100%;
					float: none;
				}
			}
		}
		.webform-client-form{
			padding: 136px 132px 0 28px;
		}
		.view-about-us-tabs {
			.view-content {
				> .views-row {
					margin: 0 auto;
    				margin-bottom: 15px;
				}
			}
		}

	}
}

@include respond-to('mobile'){
	.node-type-m-about{
		.paragraphs-item-pb-our-adv-ins{
			margin-bottom: 10px;
		}
		.paragraphs-item-pb-double-blk{
			.field-name-field-image{
				position: absolute;
				top: -402px;
				width: 320px;
				height: 400px;
				right: 50%;
				transform: translateX(50%);
				img{
					width: 320px;
					height: 400px;
				}
			}
			.field-name-field-right-back{
				width: 100%;
				float: none;
				.field-item{
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					z-index: 2;
				}
			}
			.p-container{
				position: relative;
				.paragraphs-items-field-left-side{
					position: absolute;
					top: -287px;
					right: 50%;
					width: initial;
					transform: translateX(50%);
					min-width: 300px;
				    .paragraphs-item-pb-text-area{
				    	padding: 0;
				    	.title{
				    		font-size: 18px;
				    		margin: 0;
				    		text-align: center;
				    		line-height: 1.24;
				    	}
				    	.body{
				    		text-align: center;
				    		margin: 23px 0 0 0;
				    	}
				    }
				}
				.paragraphs-items-field-right-side{
					width: 100%;
					float: none;
				}
			}
		}
		.webform-client-form{
			padding: 87px 8px 90px 8px;
		}
		.view-about-us-tabs {
			.view-content {
				> .views-row {
					margin: 0 auto;
    				margin-bottom: 0px;
    				max-width: 320px;
    				width: 100%;
				}
			}
		}

	}
}