body.node-type-page{
	.field-name-body{
		p{
			color: #999999;
			font-family: Verdana;
			font-size: 14px;
			line-height: 25px;
		}

		ol{
			> li{
				font-family: Verdana;
				font-size: 17px;
				font-weight: 400;
				color: #999;
				margin: 0 0 16px 0;
				> ul{
					padding: 16px 0 0 0;
					> li{
						&:not(:last-child){
							margin: 0 0 16px 0;
						}
					}
				}
			}
		}

	}
}