.node-m-news {
  .image-container {
    height: 150px;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    .date {
      align-items: center;
      justify-content: center;
      background-color: rgba(50,66,127,.9);
      border-radius: 50%;
      bottom: 10px;
      display: flex;
      color: #fff;
      //font-family: $GothamPro;
      font-size: 16px;
      font-weight: 900;
      height: 94px;
      line-height: 24px;
      left: 10px;
      position: absolute;
      text-align: center;
      width: 94px;
    }
  }
}
.node-m-news-teaser{
  width: 300px;
  .title-container {
    line-height: 22px;
    margin-bottom: 30px;
    min-height: 66px;
    a {
      color: $siniy;
      font-family: Verdana;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        color: #da2d29;
      }
    }
  }
  .field-name-body {
    color: #999;
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    min-height: 108px;
    a {
      color: #da2d29;
      text-decoration: none;
      /*float: right;*/
      display: inline;
      margin-left: 5px;
    }
  }
}

.node-m-news-full {
  padding-top: 80px;
  .content {
    display: inline-block;
    width: 100%;
    .left-col {
      float: left;
      width: 360px;
      @include respond-to('tablet'){
        width: 300px;
        float: none;
        margin: 0 auto;
      }
      @include respond-to('mobile'){
        width: 300px;
        float: none;
        margin: 0 auto;
      }
    }
    .right-col {
      float: left;
      width: calc(100% - 360px);
      @include respond-to('tablet'){
        width: 100%;
        float: none;
        margin-top: 40px;
      }
      @include respond-to('mobile'){
        width: 100%;
        float: none;
        margin-top: 40px;
      }
    }
  }
  .title-container {
    color: $siniy;
    font-family: Verdana;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  .field-name-body {
    color: #999;
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
  }
}

.view-id-news {
  @include make-container();
  .view-filters {
    margin-top: -42px;
    @include respond-to('tablet'){
      margin: 40px auto 0 auto;
      width: 250px;
    }
    @include respond-to('mobile'){
      margin: 40px auto 0 auto;
      width: 250px;
    }
  }
  .views-exposed-form {
    label {
      display: none;
    }
    .views-exposed-widget {
      float: right;
      padding: 0;
      @include respond-to('tablet'){
        float: none;
      }
      @include respond-to('mobile'){
        float: none;
      }
    }
  }
  .view-content{
    @include clearfix();
    @include respond-to('tablet'){
      margin-bottom: 60px;
    }
    @include respond-to('mobile'){
      margin-bottom: 60px;
    }
    display: flex;
    flex-wrap: wrap;
  }
  .views-row {
    border-bottom: 1px solid #d6d9e5;
    float: left;
    min-height: 543px;
    padding: 70px 0;
    width: 33.3333%;
    @include respond-to('desktop'){
      &:nth-child(3n+2) {
        .node-m-news-teaser {
          margin-left: auto;
          margin-right: auto;
        }
      }
      &:nth-child(3n+3) {
        .node-m-news-teaser {
          margin-left: auto;
        }
      }
    }
    @include respond-to('tablet'){
      width: 50%;
      &:nth-child(2n+2) {
        .node-m-news-teaser {
          margin-left: auto;
        }
      }
    }
    @include respond-to('mobile'){
      width: 100%;
      .node-m-news-teaser {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}