// Page Layout
// -----------------------------------------------------------------------------
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6{
  color: $siniy;
  font-family: $GothamProBlack;
  font-size: 21px;
  line-height: 1;
  margin: 0 0 20px 0;
  /*margin: 0 0 16px 0;
  padding: 29px 0 0 0;*/
}


.container{
  @include respond-to('mobile'){
    padding-left: 10px;
    padding-right: 10px;
  }
}
.dropdown-menu {
  margin: 0;
}

#page-wrapper,
#all-wrapper{
  width: 100%;
  overflow: hidden;
  position: relative;
}

#all-wrapper{
  transition:all 0.3s ease-in;
}

body{
  &:not(.node-type-doctor):not(.node-type-m-services):not(.front):not(.node-type-m-about):not(.node-type-m-educational-center){
    .field-type-image{
      .field-items{
        .field-item{
          @include respond-to('desktop'){
            background-image: none!important;
          }
        }
      }
    }
  }
}



#content{
  position: relative;
}

:active, :hover, :focus, a,a:active, a:hover, a:focus,.btn:focus, .media-widget>a:focus, .page-question-and-answer .view-header a:focus, .btn.focus, .media-widget>a.focus, .page-question-and-answer .view-header a.focus, .btn:active:focus, .media-widget>a:active:focus, .page-question-and-answer .view-header a:active:focus, .btn:active.focus, .media-widget>a:active.focus, .page-question-and-answer .view-header a:active.focus, .btn.active:focus, .media-widget>a.active:focus, .page-question-and-answer .view-header a.active:focus, .btn.active.focus, .media-widget>a.active.focus, .page-question-and-answer .view-header a.active.focus {
    outline: 0;
    outline-offset: 0;
}
.view-filters select.form-control,
.view-filters select {
  appearance: none !important;
  background: $navyblue url(../images/select_arrow_down.png) right 25px center no-repeat;
  border-radius: 16px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  padding: 0 40px 0 31px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  min-width: 250px;
}
.page-title-container {
  margin-top: 86px;
  text-align: center;
  .page-title {
    font-family: $GothamProBlack;
    border-bottom: 2px solid #da2d29;
    color: $siniy;
    display: inline-block;
    font-size: 21px;
    line-height: 36px;
    padding: 0 27px;
    margin: 0;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
  }
}
.red {
  color: #da2d29;
}
// .container-pane {
//   .pane-content {
//     @include make-container();
//   }
// }
.field-name-body, 
.field-type-text-with-summary,
.field-name-field-bottom-body {
  img {
    max-width: 100%;
    height: auto;    
  }
  /*ul {
    margin: 0 0 30px 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      &:before {
        background-color: #da2d29;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 5px;
        margin-right: 7px;
        vertical-align: middle;
        width: 5px;
      }
    }
  }*/
  ul{
    padding: 0;
    margin: 0 0 30px 0;
    li{
      list-style: none;
      font-family: Verdana;
      font-size: 17px;
      font-weight: 400;
      color: #999;
      position: relative;
      padding-left: 15px;
      margin: 0 0 16px 0;
      @include respond-to('tablet'){
        text-align: left;
      }
      @include respond-to('mobile'){
        font-size: 14px;
        text-align: left;
      }
      &:before{
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #da2d29;
      }
    }
  }

  ul{
    > li{
      > ul{
        margin: 0;
        padding: 16px 0 0 0;
      }
    }
  }
  p {
    margin-bottom: 30px;
  }
}
blockquote {
  background: url(../images/quote_icon.png) left 50px center no-repeat;
  background-color: $navyblue;
  border-radius: 30px;
  color: #fff;
  font-family: Verdana;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  margin: 0 0 30px 0;
  padding: 35px 65px 35px 170px;
  @include respond-to('mobile'){
    padding: 80px 10px 35px 10px;
    background-position: top 15px center;
  }
}
#header {
  background-color: #fff;
  border-top: 2px solid #da2d29;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

/*.item-list ul.pagination>li{
  &.pager-first, &.pager-last{
    display: none;
  }
  &>a,&>span{
    border: 0;
    background: transparent;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: $siniy;
    font-family: Verdana;
    &:hover{
      background-color: transparent;
    }
  }
  &>span{
    color: #da2d29;
  }
}*/

.item-list{
  ul.pagination.pager{
    margin: 24px 0 0 0;
    li{
      &.pager-first,
      &.pager-last{
        display: none;
      }
      >*{
        font-family: Verdana;
        font-size: 18px;
        font-weight: 700;
        color: $siniy;
        text-decoration: none;
        padding: 0 5px;
        border: 0;
        &:hover,
        &:focus{
          text-decoration: none;
          outline: 0;
          color: #da2d29;
          background-color: transparent;
        }
      }
      &.active{
        >*{
          color: #da2d29;
          background-color: transparent;
        }
      }
      &.pager-previous{
        >*{
          padding: 0 28px 0 0px;
          font-size: 0;
          text-indent: -9999px;
          min-height: 9px;
          background: url('../images/left.png') bottom left no-repeat;
          margin-top: -17px;
        }
      }
      &.pager-next{
        >*{
          padding: 0 0px 0 28px;
          font-size: 0;
          text-indent: -9999px;
          min-height: 9px;
          background: url('../images/right.png') bottom right no-repeat;
          margin-top: -17px;
        }
      }
      &.pager-ellipsis{
        >*{
          padding: 0 4px;
          &:hover{
            color: $siniy;
          }
        }
      }
    }
  }
}

.ajax-progress.ajax-progress-throbber{
  display: none;
}

#gmap-canvas{
  height: 786px;
}

body{
  &:not(.front):not(.node-type-doctor):not(.node-type-m-about):not(.node-type-m-educational-center):not(.node-type-m-services){
    #footer{
      margin: 111px 0 0 0;
    }
  }

}

#footer{
  margin: 0;
  position: relative;
  .f-container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .map-adress-block{
      display: flex;
      flex-direction:row;
      justify-content: center;
      //margin: 0 0 23px 0;
      margin: 0 0 60px 0;
      @include respond-to('tablet'){
        flex-direction:column;
        justify-content:flex-start;
        align-items:center;
        margin: 0 0 22px 0;
      }
      @include respond-to('mobile'){
        flex-direction:column;
        justify-content:flex-start;
        align-items:center;
        margin: 0 0 22px 0;
      }
      .mab-row{
        width: 353px;
        height: 90px;
        background-color: #da2d29;
        cursor: pointer;
        display: flex;
        flex-direction:row;
        @include respond-to('tablet'){
          width: 320px;
        }
        @include respond-to('mobile'){
          width: 320px;
        }
        &:not(:last-child){
          margin-right: 1px;
          @include respond-to('tablet'){
            margin-right: 0px;
            margin-bottom: 1px;
          }
          @include respond-to('mobile'){
            margin-right: 0px;
            margin-bottom: 1px;
          }
        }
        &.active,
        &:hover{
          background-color: $navyblue;
        }
        .mr-img{
          width: 59px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          @include respond-to('tablet'){
            width: 42px;
          }
          @include respond-to('mobile'){
            width: 42px;
          }
        }
        .mr-wrap{
          width: calc(100% - 59px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 19px;
          color: white;
          font-family: Verdana;
          font-size: 14px;
          @include respond-to('tablet'){
            width: calc(100% - 42px);
          }
          @include respond-to('mobile'){
            width: calc(100% - 42px);
          }
          .mr-tel{
            font-weight: 700;
            margin-bottom: 6px;
          }
          .mr-addr{
            font-weight: 400;
          }
        }
      }
    }
    .copyright{
      text-align: center;
      color: $navyblue;
      font-family: Verdana;
      font-size: 13px;
      font-weight: 400;
      margin: 0 0 19px 0;
      p{
        margin: 0;
      }
    }
  }
}

.alert-danger{
  display: none;
}

#vk_groups{
  margin: 0 auto 10px auto;
  opacity: 0.8;
}

.credit_link {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .credit_part {
    text-align: center;
    width: 50%;
    padding-bottom: 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: white;
      font-family: Verdana;
      font-size: 16px;
      font-weight: 400;
      height: 52px;
      line-height: 52px;
      background-color: #da2d29;
      border-radius: 26px;
      border: 0;
      outline: 0;
      box-shadow: none;
      text-align: left;
      padding: 0px 20px;
      margin: 0 auto;
      background-color: rgba(50,66,127,0.9);
      display: inline-block; 
      text-align: center;
    }
  }
}

#cboxTitle {
  bottom: 0px;
  padding-left: 50px;
  display: block!important;
  padding-left: 115px;
  padding-right: 25px;
  text-align: center;
  padding: 0px!important;
  height: auto!important;
}