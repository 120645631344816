body.node-type-story{
	#footer {
    	margin-top: 10px!important;
	} 
}

body.page-story{
	.page-title-container{
		.page-title{
			@include respond-to('tablet'){
				padding: 0;
			}
			@include respond-to('mobile'){
				padding: 0;
			}
		}
	} 
}
/*
.action-links {
	display: none;
}
*/
.node-story-full {
	padding-top: 35px;	
}
.view-story {
	position: relative;
	.view-filters {
		position: absolute;
		right: -23px;
		top: -38px;
		@include respond-to('tablet'){
			position: relative;
			right: 0;
			top: 0;
			width: 250px;
			margin: 40px auto 0 auto;
			.views-exposed-widgets{
				margin: 0;
			}
			.views-exposed-widget{
				float: none;
				padding: 0;
			}
		}
		@include respond-to('mobile'){
			position: relative;
			right: 0;
			top: 0;
			width: 250px;
			margin: 40px auto 0 auto;
			.views-exposed-widgets{
				margin: 0;
			}
			.views-exposed-widget{
				float: none;
				padding: 0;
			}
		}
	}

	.view-content {
		padding-top: 70px;
	}
	.views-row {
		border-bottom: 1px solid #999999;
    	margin: 0px 0 46px 0;
    	padding: 0 0 44px 0;
	}

	.story-image-container { 
		width: 442px;
    	margin: 0;
    	padding: 0;
    	padding-left: 2px;
    	padding-top: 24px;
    	border: 0;
    	display: inline-block;
		.field-name-field-story-before-image {
			position: relative;
			float: left;
			margin-right: 10px;
			&:before{
				content: 'До';
				position: absolute;
				width: 111px;
				height: 30px;
				border-radius: 28px 28px 0px 0px;
				bottom: 0;
				left: 50%;
				transform:translateX(-50%);
				color: white;
				font-family: Verdana;
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				text-align: center;
				padding: 5px 0 0 0;
				background-color: #da2d29;
			}
		}	

		.field-name-field-story-after-image {
			position: relative;
			float: left;
			&:before{
				content: 'После';
				position: absolute;
				width: 111px;
				height: 30px;
				border-radius: 28px 28px 0px 0px;
				bottom: 0;
				left: 50%;
				transform:translateX(-50%);
				color: white;
				font-family: Verdana;
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				text-align: center;
				padding: 5px 0 0 0;
				background-color: $navyblue;
			}
		}	
	}

	.teaser-text-container {
		float: right;
    	width: 758px;
		padding-left: 50px;
		font-family: Verdana;
		font-size: 14px;
		color: #999999;
		line-height: 27px;
    	.teaser-title {
    		font-size: 15px;
    		padding-top: 28px;
    		padding-bottom: 16px;
    		line-height: 1.5;
    		a {
				color: #333333;
				text-decoration: none;
				font-weight: bold;
				&:hover {
					color: #da2d29;
				}
    		}
    	}
	}

	.field-items{
		.field-item {
			.more-link {
				color: #da2d29;
				text-decoration: none;
				font-weight: bold;
				padding-left: 78px;
			}
		}	
	}
}

.top-col {
	display: flex;
	padding-bottom: 44px;
	padding-top: 56px;
	padding-left: 18px;
	@include respond-to('tablet'){
		flex-direction:column;
		padding-bottom: 0px;
		padding-left: 0px;
	}
	@include respond-to('mobile'){
		flex-direction:column;
		padding-bottom: 0px;
		padding-left: 0px;
	}
	.image-container {
		@include respond-to('tablet'){
			margin-left: auto;
			margin-right: auto;
		}
		@include respond-to('mobile'){
			margin-left: auto;
			margin-right: auto;
		}
		.field-name-field-story-before-image {
			position: relative;
			img{
				max-width: 100%;
				height: auto;
			}
			&:before{
				content: 'До';
				position: absolute;
				width: 161px;
				height: 46px;
				border-radius: 28px 28px 0px 0px;
				bottom: 0;
				left: 50%;
				transform:translateX(-50%);
				color: white;
				font-family: Verdana;
				font-size: 20px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				padding: 10px 0 0 0;
				background-color: #da2d29;
			}
		}
	}

	.text-container {
		padding-left: 60px;
		color: #999999;
		font-size: 14px;
		font-family: Verdana;
		@include respond-to('tablet'){
			padding-left: 0px;
			margin-top: 25px;
		}
		@include respond-to('mobile'){
			padding-left: 0px;
			margin-top: 25px;
		}
		.field-label {
			color: #da2d29;
			padding-bottom: 28px;
		}
		.field-items {
			.field-item {
				line-height: 1.94;
				ul {
					line-height: 2.2;
				}
			}
		}
	}
}



.bottom-col {
	display: flex;
	padding-bottom: 74px;
	padding-right: 5px;
	@include respond-to('tablet'){
		flex-direction:column;
	}
	@include respond-to('mobile'){
		flex-direction:column;
	}
	.image-container {
		@include respond-to('tablet'){
			margin-left: auto;
			margin-right: auto;
		}
		@include respond-to('mobile'){
			margin-left: auto;
			margin-right: auto;
		}
		.field-name-field-story-after-image {
			position: relative;
			&:before{
				content: 'После';
				position: absolute;
				width: 161px;
				height: 46px;
				border-radius: 28px 28px 0px 0px;
				bottom: 0;
				left: 50%;
				transform:translateX(-50%);
				color: white;
				font-family: Verdana;
				font-size: 20px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				padding: 10px 0 0 0;
				background-color: $navyblue;
			}
			img{
				max-width: 100%;
				height: auto;
			}
		}
	}

	.text-container {
		padding-right: 75px;
		color: #999999;
		font-size: 14px;
		font-family: Verdana;
		line-height: 1.94;
		@include respond-to('tablet'){
			padding-right: 0px;
		}
		@include respond-to('mobile'){
			padding-right: 0px;
		}
	}
	.image-container{
		padding-top: 8px;
	}
}

.review {
	position: relative;
	background-color: #f9f9f9;
	margin: 0;
	padding: 86px 0 88px 0;
	border: 0;
	text-align: center;
	&:before {
		position: absolute;
		top: 0;
		left: -300%;
		width: 300%;
		height: 100%;
		background-color: #f9f9f9;
		content: "";
	}
	&:after {
		position: absolute;
		top: 0;
		right: -300%;
		width: 300%;
		height: 100%;
		background-color: #f9f9f9;
		content: "";
	}

	.quote {
		background: url(../images/quotes.png);
		background-repeat: no-repeat;
		background-position: center;
		width: 36px;
		height: 23px;
		margin: 0px auto 30px auto;
		padding: 0;
	} 

	.review-text {
		font-family: TimesNewRoman;
		font-style: italic;
		font-size: 20px;
		color: #747474;
		line-height: 1.74;
		padding: 0 79px;
		@include respond-to('tablet'){
			padding: 0px;
		}
		@include respond-to('mobile'){
			padding: 0;
		}
	}

	.review-signature {
		color: #da2d29;
		font-family: Verdana;
		font-weight: bold;
		font-size: 14px;
		padding-top: 8px;
	}
}


@media screen and (max-width: 767px) {
   .view-story {
   		.story-image-container { 
			width: 100%;
	    	border: 0;
	    	padding: 0;
	    	display: flex;
    		flex-direction: column;
    		align-items: center;
			.field-name-field-story-before-image {
				position: relative;
				margin: 0 0 10px 0;
				.field-label {
					width: 110px;
					height: 30px;
					padding: 5px;
					border-radius: 25px 25px 0 0;
					text-align: center;
					background-color: #da2d29;
					position: absolute;
					color: #ffffff;
					bottom: 0;
					left: 54px;
					font-family: Verdana;
					font-size: 14px;
				}
		}	

			.field-name-field-story-after-image {
				position: relative;
				.field-label {
					width: 110px;
					height: 30px;
					padding: 5px;
					border-radius: 25px 25px 0 0;
					text-align: center;
					background-color: $navyblue;
					position: absolute;
					color: #ffffff;
					bottom: 0;
					left: 54px;
					font-family: Verdana;
					font-size: 14px;
				}
			}	
		}

		.teaser-text-container {
    		text-align: center;
    		width: 100%;
			font-family: Verdana;
			font-size: 14px;
			color: #999999;
			line-height: 27px;
			float: none;
			padding-left: 0;
			display: flex;
			flex-direction: column;
    		align-items: center;
    		margin: 0 auto; 
    		.teaser-title {
    			font-size: 15px;
    			padding-top: 28px;
    			padding-bottom: 16px;
    			line-height: 1.5;
    			a {
					color: #da2d29;
					text-decoration: none;
					font-weight: bold;
    			}
    		}

		.field-items {
			.field-item {
				.more-link {
					display: block;
					text-align: center;
					padding-left: 0;
				}	
			}
		}
	}
	}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
	.view-story {
   		.story-image-container { 
			width: 100%;
	    	border: 0;
	    	padding: 0;
	    	display: flex;
	    	justify-content: center;
			.field-name-field-story-before-image {
				position: relative;
				margin: 0 10px 0 0;
				.field-label {
					width: 110px;
					height: 30px;
					padding: 5px;
					border-radius: 25px 25px 0 0;
					text-align: center;
					background-color: #da2d29;
					position: absolute;
					color: #ffffff;
					bottom: 0;
					left: 54px;
					font-family: Verdana;
					font-size: 14px;
				}
		}	

			.field-name-field-story-after-image {
				position: relative;
				.field-label {
					width: 110px;
					height: 30px;
					padding: 5px;
					border-radius: 25px 25px 0 0;
					text-align: center;
					background-color: $navyblue;
					position: absolute;
					color: #ffffff;
					bottom: 0;
					left: 54px;
					font-family: Verdana;
					font-size: 14px;
				}
			}	
		}

		.teaser-text-container {
    		text-align: center;
    		width: 100%;
			font-family: Verdana;
			font-size: 14px;
			color: #999999;
			line-height: 27px;
			float: none;
			padding-left: 0;
			display: flex;
			flex-direction: column;
    		align-items: center;
    		margin: 0 auto; 
    		.teaser-title {
    			font-size: 15px;
    			padding-top: 28px;
    			padding-bottom: 16px;
    			line-height: 1.5;
    			a {
					color: #da2d29;
					text-decoration: none;
					font-weight: bold;
    			}
    		}

		.field-items {
			.field-item {
				.more-link {
					display: block;
					text-align: center;
					padding-left: 0;
				}	
			}
		}
	}
	}
}

