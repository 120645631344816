
body.page-thanks {
	#footer{
		margin-top: 0px!important;
	}
	.background-thanks {
		height: 855px;
		background-image: url(../images/bg-thanks.jpg);
		background-repeat: repeat;
		background-position: center;
		background-size: cover;
		.title {
			font-family: $CafedeFranceDeco;
			font-size: 80px;
			color: #ffffff;
			display: block;
			text-align: center;
			padding-top: 322px;
			line-height: 1;
			margin-bottom: 15px;
			@include respond-to('mobile'){
				font-size: 56px;
			}
		}
		.sub-title {
			font-family: $GothamProLight;
			color: #ffffff;
			font-size: 20px;
			letter-spacing: 1.7px;
			display: block;
			text-align: center;
			margin-bottom: 49px;
			line-height: 1;
			@include respond-to('mobile'){
				font-size: 18px;
			}
		}

		.button {
			margin: 0 auto;
			font-size: 16px;
			width: 300px;
			background-color: #da2d29;
			display: flex;
			font-family: $GothamProLight; 
			padding: 15px 20px 15px 44px;
			color: #ffffff;
			border-radius: 100px;
			text-decoration: none;
			background-image: url(../images/more-arrows-hover.png);
			background-repeat: no-repeat;
			background-position: center right 40px;
		}
	}
}