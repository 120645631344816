form.webform-client-form.webform-client-form-1294,
form.webform-client-form.webform-client-form-1295 {
	padding: 0px;
	padding-top: 30px;
	.webform-component--zagolovok-formy {
		color: #004a80;
		font-family: "GothamProBlack";
		font-size: 21px;
		line-height: 1;
		margin: 0 0 20px 0;
		text-align: center;
		p {
			border-bottom: 2px solid rgb(218, 45, 41); 
			display: inline-block;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 10px;
		}
	}
	.webform-component--etap-1 {
		color: #da2d29;
		font-family: Verdana;
		font-size: 14px;
		font-weight: 700;
		line-height: 27px;
		text-align: center;
		text-transform: initial;
		padding-top: 62px;
		margin-bottom: 3px;
		padding-bottom: 10px;
	}
	select {
		color: dimgrey;
		font-family: Verdana;
		font-size: 14px;
		font-weight: 400;
		line-height: 50px;
		background-color: #f2f2f2;
		border-radius: 25px;
		border: 0px;
		box-shadow: none; 
		padding-left: 40px;
	}
	textarea {
		background-color: #f2f2f2;
		border-radius: 25px;
		border: 0px;
		padding: 17px 44px;
		outline: none;
		height: 153px;
		color: dimgrey;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: dimgrey;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: dimgrey;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: dimgrey;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: dimgrey;
		}
	}
	.webform-component-textfield {
		input {
			color: dimgrey;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 50px;
			background-color: #f2f2f2;
			border-radius: 25px;
			border: 0px;
			box-shadow: none;
			padding-left: 40px;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: dimgrey;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: dimgrey;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: dimgrey;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: dimgrey;
			}
		}
	}
	.webform-component--vyberite-filial {
		float: left;
		width: 50%;
		padding-right: 26px;
		@media screen and (max-width: 767px) {
			padding-right: 0px;
			padding-left: 0px;
			width: 100%;
		}
	}
	.webform-component--data-naznacheniya {
		float: right;
		width: 50%;
		padding-left: 26px;
		input {
			background-image: url('../images/webform_date.png');
			background-position: top 12px right 24px;
			background-repeat: no-repeat; 
		}
		@media screen and (max-width: 767px) {
			padding-right: 0px;
			padding-left: 0px;
			width: 100%;
		}
	}
	.webform-component--fio,
	.webform-component--data-rozhdeniya,
	.webform-component--semeynoe-polozhenie {
		float: left;
		width: 50%;
		padding-right: 26px;
		@media screen and (max-width: 767px) {
			padding-right: 0px;
			padding-left: 0px;
			width: 100%;
		}
	}
	.webform-component--data-rozhdeniya {
		input {
			background-image: url('../images/webform_date.png');
			background-position: top 12px right 24px;
			background-repeat: no-repeat; 
		}
	}
	.webform-component--pol,
	.webform-component--adres,
	.webform-component--rod-zanyatiy {
		float: right;
		width: 50%;
		padding-left: 26px;
		@media screen and (max-width: 767px) {
			padding-right: 0px;
			padding-left: 0px;
			width: 100%;
		}
	}
	.webform-component--otvette-na-voprosy,
	.webform-component--dlya-zhenshchin {
		clear: both;
		color: #da2d29;
        font-family: Verdana;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        text-transform: initial;
    	padding-top: 53px;
	}
	.webform-component--otvette-na-voprosy {
		margin-bottom: 39px;
	}
	.webform-component--dlya-zhenshchin {
		padding-top: 20px;
	}
	.webform-component-radios {
		max-width: 694px;
		margin: 0 auto;
		display: flex; 
		margin-bottom: 31px;
		@media screen and (max-width: 767px) {
			flex-wrap:wrap;
			padding-left: 10px;
			padding-right: 10px;
		}
		label {
			color: #555;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 27px;
			width: 100%;
			line-height: 17px;
			width: 100%;
			padding-top: 5px;
		}
		&>label {
			@media screen and (max-width: 767px) {
				text-align: center;
				padding-bottom: 10px;
			}
		}
		.form-radios {
			width: 211px;
			display: flex;
			justify-content: flex-end;
			@media screen and (max-width: 767px) {
				justify-content: center;
				width: 100%;
			}
		}
		.form-type-radio {
			margin: 0px; 
			input {
				display: none;
			}
			label {
				color: #32427f;
				font-family: Verdana;
				font-size: 14px;
				font-weight: 700;
				line-height: 27px;
				background: url('../images/webform_radio.png') top left no-repeat;
				display: inline-block;
				min-height: 29px;
				padding-left: 40px;
				margin-right: 0px;
				margin-left: 30px;
				padding-top: 0px;
			}
			input:checked+label {
				background: url('../images/webform_radio_active.png') top left no-repeat;
			}
		}
	}
	.webform-component--otmette-te-sostoyaniya-kotorye-u-vas-kogda-libo-byli-ili-imeyutsya-v-nastoyashchee-vremya,
	.webform-component--otmette-te-sostoyaniya-kotorye-kogda-libo-byli-u-rebyonka-ili-imeyutsya-v-nastoyashchee-vremya {
		&>label {
			padding-bottom: 49px;
			color: #da2d29;
	        font-family: Verdana;
	        font-size: 18px;
	        font-weight: 400;
	        line-height: 27px;
	        text-align: center;
	        text-transform: lowercase;
	        display: block;
	        text-align: center;
	        margin-bottom: 0px;
		}
		clear: both;
    	padding-top: 53px;
    	margin-bottom: 1px;
    	.form-type-checkbox {
    		width: 33.33%;
    		text-align: left;
    		margin-top: 0px;
    		@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 10px;
				padding-right: 10px;
			}
    		label {
    			text-align: left;
				color: #555;
				font-family: Verdana;
				font-size: 14px;
				font-weight: 400;
				background: url('../images/webform_checked.png') top left no-repeat;
				display: inline-block;
				min-height: 29px;
				padding-left: 40px;
				display: flex;
				align-items:center;
				padding-right: 10px; 
    		}
    		input:checked+label {
				background: url('../images/webform_checked_active.png') top left no-repeat;
			}  
			input {
				display: none;
			}
    	}
		.form-checkboxes {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.webform-component--u-vas-est-zabolevaniya-sostoyaniya-ili-problemy-kotorye-ne-perechisleny-v-ankete-kakie {

	}
	.webform-component--data {
	    margin: 0 auto;
    	width: 50%;
    	@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.webform-component--podtverzhdayu {
		padding-top: 27px;
		label {
			text-align: left;
			color: #555;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			background: url('../images/webform_checked.png') top left no-repeat;
			display: inline-block;
			min-height: 29px;
			padding-left: 40px;
		}
		input:checked+label {
			background: url('../images/webform_checked_active.png') top left no-repeat;
		}  
		input {
			display: none;
		} 
		.form-checkboxes {
			.form-item-submitted-podtverzhdayu-agree {
				text-align: center;
			}
		}
	}
	.webform-component--etap-2 {
		text-transform: initial;
		.required_fields {
			font-size: 15px;
		    color: #555555;
		    text-align: center;
		    font-family: Verdana;
		}
		.step2 {
			color: #da2d29;
			font-size: 18px;
			text-align: center;
			font-family: Verdana;
			padding-top: 42px;
		}
		.download_file {
			text-align: center;
			background-image: url(../images/icon/file-icon.png);
			background-repeat: no-repeat;
			height: 60px;
			width: 365px;
		    margin: 0 auto;
		    padding-top: 10px; 
		    font-family: Verdana;
		    margin-top: 39px;
		    @media screen and (max-width: 400px) {
				width: auto;
				padding-left: 60px;
				text-align: left;
			}
			a {
				color: #555555;
				font-size: 14px;
				text-decoration: none;
				padding-left: 27px;
				@media screen and (max-width: 400px) {
					padding-left: 0px;
				}
			}
		}
		.first_ster {
			text-align: center;
			font-size: 21px;
			color: #32427f;
			padding-top: 83px;
		}
	}
	.form-actions {
		float: none;
		text-align: center;
		margin: 20px 0 30px 0 ; 
	}
}

.form-actions { 
	input[type="submit"]:hover {
		opacity: 0.9; 
	}
}