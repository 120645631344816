.twenty-year{
	background: url('../images/bg-twenty-year.jpg') center center / cover no-repeat;
	height: 854px;
	position: relative;
	.container {
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 601px;
			background: url('../images/front_main_img_shadow.png') bottom center;
		}
	}
	.ty-title{
		text-shadow: 0 0 10.6px rgba(35, 8, 0, 0.12);
		color: white;
		font-family: $CafedeFranceDeco;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		font-size: 121px;
		text-align: center;
		margin: 79px 0 0 0;
		position: relative;
		z-index: 1;
		@include respond-to('tablet'){
		}
		@include respond-to('mobile'){
			font-size: 80px;
			margin: 132px 6px 0 6px;
		}
	}
	.ty-subtitle{
		text-shadow: 0 0 10.6px rgba(35, 8, 0, 0.12);
		color: white;
		font-family: $CafedeFranceDeco;
		font-weight: 400;
		line-height: 1;
		//text-transform: uppercase;
		font-size: 61px;
		text-align: center;
		margin: 16px 0 0 10px;
		letter-spacing: 6px;
		position: relative;
		z-index: 1;
		@include respond-to('tablet'){
		}
		@include respond-to('mobile'){
			font-size: 28px;
			margin: 9px 0 0 0px;
			letter-spacing: 2px;
		}
	}
	.ty-discount{
		color: white;
		font-family: $PlayfairDisplaySC;
		font-size: 20px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		width: 1151px;
		height: 117px;
		text-align: center;
		margin: 7px auto 0 auto;
		padding: 30px 0 0 0;
		background: url('../images/bg-discount-red-tape.png') center center / cover no-repeat;
		position: relative;
		z-index: 1;
		@include respond-to('tablet'){
			text-shadow: 0 0 10.6px rgba(35, 8, 0, 0.12);
			font-size: 30px;
			background: none;
			width: 100%;
			height: auto;
			margin: 42px auto 0 auto;
			padding: 0px 0 0 0;
			line-height: 1.25;
		}
		@include respond-to('mobile'){
			text-shadow: 0 0 10.6px rgba(35, 8, 0, 0.12);
			font-size: 20px;
			background: none;
			width: 100%;
			height: auto;
			margin: 26px 0px 0 0px;
		    padding: 0px 15px;
		    line-height: 1.45;
		}
	}
	.read_more{
		position: relative;
		z-index: 1;
		text-align: center;
		@include respond-to('tablet'){
			padding-top: 20px;
		}
		@include respond-to('mobile'){
			padding-top: 20px;
		}
		a {
			display: inline-block;
			text-decoration: none;
		    color: white;
		    font-family: Verdana;
		    font-size: 16px;
		    font-weight: 400;
		    width: 175px;
		    height: 52px;
		    line-height: 52px;
		    background-color: #da2d29;
		    border-radius: 26px;
		    border: 0;
		    outline: 0;
		    box-shadow: none;
		    text-align: left;
		    padding: 0 0 0 30px;
		    margin: 0 auto;
		    background-image: url(../images/more-arrows-hover.png);
		    background-repeat: no-repeat;
		    background-position: center right 30px;
		    &:hover {
		    	opacity: 0.9;
		    }
		}
	}
	.four-col{
		margin: 67px 0 0 0;
		position: relative;
		z-index: 1;  
		@include respond-to('desktop'){
			display: flex;
			flex-direction:row;
		}
		@include respond-to('tablet'){
			margin: 56px 0 0 0;
		}
		@include respond-to('mobile'){
			margin: 43px 0 0 0;
		}
		a {
			text-decoration: none; 
		}
		.owl-item {
			.fc-col .fc-title,.fc-body {
				color: #da2d29!important;
			}
			&:nth-child(2n) {
				.fc-col .fc-title,.fc-body {
					color: #004a80!important;
				}
			}
		}
		.fc-col{
			.fc-title {
				a {
					font-family: "GothamProBlack";
				}
			}
			.fc-title,.fc-body {
				color: #da2d29!important;
				a {
					color: #da2d29!important;
				}
			}
			&:nth-child(2n) {
				.fc-title,.fc-body {
					color: #004a80!important;
					a {
						color: #004a80!important;
					}
				}
			}
			@include respond-to('desktop'){
				height: 269px;
				&:not(:last-child){
					border-right: 2px solid rgba(255,255,255,.2);
					margin-right: 59px;
					padding-right: 42px;
				}
			}
			@include respond-to('tablet'){
				width: 232px;
				margin: 0 auto;
				text-align: center;
			}
			@include respond-to('mobile'){
				width: 232px;
				margin: 0 auto;
				text-align: center;
			}
			.fc-img{
				@include respond-to('tablet'){
					min-height: 69px;
				}
				@include respond-to('mobile'){
					min-height: 69px;
				}
			}
			.fc-title{
				color: white;
				font-family: $GothamProBlack;
				font-size: 17px;
				text-transform: uppercase;
				margin: 27px 0 0 0;
				@include respond-to('tablet'){
					margin: 22px 0 0 0;
				}
				@include respond-to('mobile'){
					margin: 22px 0 0 0;
				}
			}
			.fc-body{
				color: white;
				font-family: Verdana;
				font-size: 14px;
				font-weight: 400;
				margin: 16px 0 0 0;
				line-height: 1.74;
			}
		}
		.owl-nav{
			margin: 0;
			.owl-prev{
				width: 14px;
				height: 25px;
				margin: 0;
				padding: 0;
				background: url('../images/left-white.png') no-repeat;
				position: absolute;
				top: 19px;
				left: 0;
				font-size: 0;
				text-indent: -9999px;
				&:hover{
					background-color: transparent;
				}
			}
			.owl-next{
				width: 14px;
				height: 25px;
				margin: 0;
				padding: 0;
				background: url('../images/right-white.png') no-repeat;
				position: absolute;
				top: 19px;
				right: 0;
				font-size: 0;
				text-indent: -9999px;
				&:hover{
					background-color: transparent;
				}
			}
		}
	}
}

@include respond-to('desktop'){
	@for $i from 1 through 28{
		.ch#{$i}{
			display: inline-block;
			position: relative;
			top:(($i / 10) + 1)+px;
		}
	}

	@for $i from 29 through 56{
		.ch#{($i)}{
			display: inline-block;
			position: relative;
			top:(((57 - $i) / 10) + 1)+px;
		}
	}
}

#block-block-3{
	position: absolute;
	top: 364px;
	left: 23px;
	z-index: 10; 
	@media screen and (min-width: 1200px)  {
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
	}
	@include respond-to('tablet'){
		top: 97px;
		left: 40px;
		.block__content{
			display: flex;
			flex-direction:row-reverse;
		}
	}
	@include respond-to('mobile'){
		display: none;
		.block__content{
			display: flex;
			flex-direction:row-reverse;
		}
	}
	.social{
		margin: 0 0 11px 0;
		@include respond-to('tablet'){
			margin: 0 10px 0px 0;
		}
		@include respond-to('mobile'){
			margin: 0 10px 0px 0;
		}
		a{
			display: block;
		}
	}
}

.our-pacient{
	height: 666px;
	position: relative;
	@include respond-to('tablet'){
		height: 923px;
	}
	@include respond-to('mobile'){
		height: 1513px;
		margin-bottom: 2px;
	}
	&:after{
		content: '';
		position: absolute;
		height: 100%;
		width: 50%;
		top: 0;
		right: 0;
		background: url('../images/front-bg-our-pacient.jpg') top center / cover no-repeat;
		@include respond-to('tablet'){
			width: 320px;
			height: 400px;
			top: auto;
			bottom: 91px;
			right: auto;
			left: calc((100% - 688px + 24px) / 2);
		}
		@include respond-to('mobile'){
			width: 320px;
			height: 400px;
			top: auto;
			bottom: 402px;
			right: auto;
			left: 50%;
			transform:translateX(-50%);
		}
	}
	.op-container{
		position: relative;
		z-index: 10;
	}
	.op{
		@include respond-to('desktop'){
			width: 50%;
			float: left;
		}
	}
	.op-1{
		padding-right: 80px;
		padding-top: 137px;
		@include respond-to('tablet'){
			padding-right: 0px;
			text-align: center;
			padding: 89px 15px 0 15px;
		}
		@include respond-to('mobile'){
			padding-right: 0px;
			text-align: center;
			padding: 88px 0px 0 0px;
		}
		.op1-title{
			color: dimgrey;
			font-family: $GothamProBlack;
			font-size: 23px;
			text-transform: uppercase;
			line-height: 1.38;
			@include respond-to('tablet'){
				font-size: 18px;
				line-height: 1.25;
			}
			@include respond-to('mobile'){
				font-size: 18px;
				line-height: 1.25;
			}
		}
		.op1-body{
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			color: #999;
			margin: 31px 0 0 0;
			line-height: 1.94;
			@include respond-to('tablet'){
				margin: 23px 0 0 0;
				line-height: 1.82;
			}
			@include respond-to('mobile'){
				margin: 30px 0 0 0;
				line-height: 1.82;
			}
		}
		.op1-mission{
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			color: #da2d29;
			text-transform: uppercase;
			margin: 21px 0 0 3px;
			letter-spacing: 0.2px;
			@include respond-to('tablet'){
				margin: 28px 0 0 0px;
			}
			@include respond-to('mobile'){
				margin: 25px 0 0 0px;
				line-height: 1.8;
			}
		}
	}
	.op-2{
		text-align: right;
		padding-top: 252px;
		@include respond-to('tablet'){
			text-align: center;
			width: 50%;
			padding-top: 183px;
			padding-left: 5px;
		}
		@include respond-to('mobile'){
			text-align: center;
		    width: 100%;
		    max-width: 320px;
		    padding-top: 208px;
		    padding-left: 5px;
		    margin-left: auto;
		    margin-right: auto;
		}
		.op2-title{
			color: #da2d29;
			font-family: $GothamProBlack;
			font-size: 31px;
			text-transform: uppercase;
			line-height: 1;
			@include respond-to('tablet'){
				font-size: 18px;
				line-height: 1.22;
			}
			@include respond-to('mobile'){
				font-size: 18px;
				line-height: 1.22;
			}
		}
		.op2-body{
			color: #666;
			font-family: Verdana;
			font-size: 13px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 16px 0 0 0;
			line-height: 1.54;
			@include respond-to('tablet'){
				margin: 27px 0 0 0;
			}
			@include respond-to('mobile'){
				margin: 26px 0 0 0;
			}
		}
	}
}

.our-doc-insert{
	padding: 114px 0 0 0;
	.view-title{
		color: #da2d29;
		font-family: Verdana;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 0px 0;
	}
	.view-content{
		padding: 54px 0 0 0;
	}
	.node-doctor{
		margin-bottom: 30px;
	}
	.field-name-field-doctor-photo{
		.field-item{
			background: none!important;
		}
	}
	.owl-carousel{
		.owl-nav,
		.owl-controls{
			margin: 0;
			.owl-prev{
				width: 14px;
				height: 25px;
				margin: 0;
				padding: 0;
				background: url('../images/left-red.png') no-repeat;
				position: absolute;
				top: 101px;
				left: 0;
				font-size: 0;
				text-indent: -9999px;
				margin: 0;
				padding: 0;
				background-color: transparent;
				opacity: 1;
				border-radius: 0;
				@include respond-to('mobile'){
					left: 9px;
				}
				&:hover{
					background-color: transparent;
				}
			}
			.owl-next{
				width: 14px;
				height: 25px;
				margin: 0;
				padding: 0;
				background: url('../images/right-red.png') no-repeat;
				position: absolute;
				top: 101px;
				right: 0;
				font-size: 0;
				text-indent: -9999px;
				margin: 0;
				padding: 0;
				background-color: transparent;
				opacity: 1;
				border-radius: 0;
				@include respond-to('mobile'){
					right: 9px;
				}
				&:hover{
					background-color: transparent;
				}
			}
		}
	}

}

.article-insert-other{
	background-color: $navyblue;
	padding-top: 150px;
	padding-bottom: 70px;
	@include respond-to('tablet'){
		padding-top: 85px;
		padding-bottom: 0px;
	}
	@include respond-to('mobile'){
		padding-top: 85px;
		padding-bottom: 0px;
	}
	.view-header{
		.view-title{
			color: white;
			font-family: Verdana;
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
			text-align: center;
		}
	}
	.view-content{
		@include make-container();
		padding: 77px 0 0 0;
		@include respond-to('tablet') {
			margin-bottom: 0;
			padding: 40px 0 0 0;
		}
		@include respond-to('mobile') {
			margin-bottom: 0;
			padding: 40px 0 0 0;
		}
		.views-row{
			@include respond-to('tablet'){
				padding: 0 33px;
				margin-bottom: 90px;
			}
			@include respond-to('mobile'){
				width: 100%;
				padding: 0;
				margin-bottom: 90px;
			}
			&:nth-child(2n+1){
				&:after{
					background-color: rgba(243,242,237,.3);
					@include respond-to('tablet'){
						content: none;
					}
					@include respond-to('mobile'){
						content: none;
					}
				}
			}
			&:nth-child(2n+2){
				@include respond-to('tablet'){
					padding: 0 33px;
				}
				@include respond-to('mobile'){
					padding: 0;
				}
			}
			.node-article{
				.left-col{
					.node-detail{
						a{
							color: white;
							background-image: url(../images/more-arrows-hover.png);
							border-color: rgba(243,242,237,.3);
						}
					}
					.field-name-field-image{
						.field-item{
							background: none!important;
						}
					}
					@include respond-to('tablet'){
						float: none;
						width: 100%;
						text-align: center;
						.node-detail{
							display: none;
						}
					}
					@include respond-to('mobile'){
						float: none;
						width: 100%;
						text-align: center;
						.node-detail{
							display: none;
						}
					}
				}
				.right-col{
					@include respond-to('tablet'){
						margin-top: 30px;
					}
					@include respond-to('mobile'){
						margin-top: 30px;
					}
					.date{
						color: #da2d29;
					}
					.title-container{
						a{
							color: white;
						}
						@include respond-to('mobile'){
							padding: 0 8px;
						}
					}
					.field-name-body{
						color: white;
						@include respond-to('tablet'){
							margin: 26px 0 0 0;
						}
						@include respond-to('mobile'){
							margin: 26px 0 0 0;
							padding: 0 15px;
						}
					}
					.node-detail{
						display: none;
						a{
							color: white;
							background-image: url(../images/more-arrows-hover.png);
							border-color: rgba(243,242,237,.3);
						}
					}
					@include respond-to('tablet'){
						float: none;
						width: 100%;
						text-align: center;
						.node-detail{
							display: block;
							a{
								margin: 32px auto 0 auto;
							}
						}
					}
					@include respond-to('mobile'){
						float: none;
						width: 100%;
						text-align: center;
						.node-detail{
							display: block;
							a{
								margin: 32px auto 0 auto;
							}
						}
					}
				}
			}
		}
	}
	.owl-nav{
		margin: 0;
		.owl-prev{
			width: 14px;
			height: 25px;
			margin: 0;
			padding: 0;
			background: url('../images/left-white.png') no-repeat;
			position: absolute;
			top: 95px;
			left: 9px;
			font-size: 0;
			text-indent: -9999px;
			&:hover{
				background-color: transparent;
			}
		}
		.owl-next{
			width: 14px;
			height: 25px;
			margin: 0;
			padding: 0;
			background: url('../images/right-white.png') no-repeat;
			position: absolute;
			top: 95px;
			right: 9px;
			font-size: 0;
			text-indent: -9999px;
			&:hover{
				background-color: transparent;
			}
		}
	}
}

@include respond-to('tablet'){
	.node-m-front{
		.paragraphs-item-pb-double-blk{
			.field-name-field-image{
				position: absolute;
				top: -490px;
				width: 320px;
				height: 400px;
				right: calc((100% - 688px + 29px) / 2);
				img{
					width: 320px;
					height: 400px;
				}
			}
			.field-name-field-right-back{
				width: 100%;
				float: none;
			}
			.p-container{
				.paragraphs-items-field-left-side{
					position: absolute;
				    top: -375px;
				    right: calc((100% - 688px + 54px) / 2);
				    width: initial;
				    .paragraphs-item-pb-text-area{
				    	padding: 0;
				    	.title{
				    		font-size: 18px;
				    		margin: 0;
				    		text-align: center;
				    		line-height: 1.24;
				    	}
				    	.body{
				    		text-align: center;
				    		margin: 23px 0 0 0;
				    	}
				    }
				}
				.paragraphs-items-field-right-side{
					width: 100%;
					float: none;
				}
			}
		}
		.webform-client-form{
			padding: 136px 132px 0 28px;
		}

		.our-doc-insert{
			padding: 115px 0 39px 0;
			.view-content{
				padding: 34px 0 0 0;
				.views-row{
					width: 100%;
				}
			}
		}

	}
}

@include respond-to('mobile'){
	.node-m-front{
		.paragraphs-item-pb-double-blk{
			.field-name-field-image{
				position: absolute;
				top: -402px;
				width: 320px;
				height: 400px;
				right: 50%;
				transform: translateX(50%);
				img{
					width: 320px;
					height: 400px;
				}
			}
			.field-name-field-right-back{
				width: 100%;
				float: none;
				.field-item{
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					z-index: 2;
				}
			}
			.p-container{
				position: relative;
				.paragraphs-items-field-left-side{
					position: absolute;
					top: -287px;
					right: 50%;
					width: initial;
					transform: translateX(50%);
					min-width: 300px;
				    .paragraphs-item-pb-text-area{
				    	padding: 0;
				    	.title{
				    		font-size: 18px;
				    		margin: 0;
				    		text-align: center;
				    		line-height: 1.24;
				    	}
				    	.body{
				    		text-align: center;
				    		margin: 23px 0 0 0;
				    	}
				    }
				}
				.paragraphs-items-field-right-side{
					width: 100%;
					float: none;
				}
			}
		}
		.webform-client-form{
			padding: 87px 8px 90px 8px;
		}

		.our-doc-insert{
			padding: 84px 0 0px 0;
			.view-content{
				padding: 34px 0 0 0;
				.views-row{
					width: 100%;
				}
			}
		}

	}
}