body.page-educational-center,
body.page-learning{

	.intermediate-1{
		padding-top: 87px;
		font-family: Verdana;
		font-size: 14px;
		line-height: 1.94;
		color: #999;
		font-weight: 400;
		margin-bottom: 0px;
	}

	.img-container{
		position: relative;

		.background{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top center;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(7, 15, 47, 0.5);
			}
			img{
				opacity: 0;
			}
		}

		.ic-container{
			min-height: 500px;
			position: relative;
			display: flex;
			flex-direction:column;
			justify-content:center;
			align-items:center;
			padding-top: 25px;
			padding-bottom: 25px;
			.ic-icon{
				/*margin-top: 101px;*/
				img{
					display: block;
					margin: 0 auto;
				}
			}
			.ic-title{
				color: white;
				font-family: Verdana;
				font-size: 30px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				margin-top: 18px;
			}
			.ic-body{
				color: white;
				font-family: Verdana;
				font-size: 15px;
				font-weight: 400;
				line-height: 1.8;
				text-align: center;
				margin-top: 29px;
				@include respond-to('tablet'){
					br{
						display: none;
					}
				}
				@include respond-to('mobile'){
					br{
						display: none;
					}
				}
			}
		}

	}

	.pane-title,
	.double-title{
		display: inline-block;
		color: $navyblue;
		font-family: $GothamProLight;
		font-size: 21px;
		text-transform: uppercase;
		padding: 0 9px 7px 9px;
		position: relative;
		margin-left: 50%;
		transform: translateX(-50%);
		margin-top: 0;
		margin-bottom: 0px;
		text-align: center;
		span{
			font-family: $GothamProBlack;
		}
		&:after{
			content: '';
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    height: 2px;
		    background-color: #da2d29;
		}
	}

	.pane-m-our-courses-panel-pane-1{
		margin-top: 87px;
	}

	.view-m-our-courses{
		margin-top: 36px;
		.view-content{
			.views-row{

			}
		}
	}

	.node-m-educational-center{
		.course-row{
			position: relative;
			min-height: 159px;
			display: flex;
			flex-direction:row;
			align-items:center;
			justify-content: flex-end;
			margin-bottom: 30px;
			@include respond-to('tablet'){
				flex-direction:column;
				justify-content: flex-start;
				margin-bottom: 40px;
			}
			@include respond-to('mobile'){
				flex-direction:column;
				justify-content: flex-start;
				margin-bottom: 40px;
			}
			.teacher-photo{
				position: absolute;
				top: 0;
				left: 0;
				@include respond-to('tablet'){
					position: relative;
					margin-bottom: 30px;
				}
				@include respond-to('mobile'){
					position: relative;
					margin-bottom: 30px;
				}
				img{
					display: block;
					border-radius: 100%;
				}
			}
			.cr-body{
				width: 1064px;
				min-height: 131px;
				background-color: white;
				border: 1px solid #ededed;
				border-radius: 0px 66px 66px 0px;
				box-shadow: 8px 8.9px 0 rgba(0, 0, 0, 0.07);
				margin-right: 47px;
				padding: 29px 114px;
				@include respond-to('tablet'){
					width: 100%;
					padding: 20px 50px 72px 50px;
					margin-right: 0px;
				}
				@include respond-to('mobile'){
					width: 100%;
					padding: 20px 10px 72px 10px;
					margin-right: 0px;
				}
				.node-title{
					color: $navyblue;
					font-family: $GothamProLight;
					font-size: 21px;
					line-height: 1.48;
				}
				.teacher-name{
					color: #da2d29;
					font-family: $GothamProBlack;
					font-size: 15px;
					line-height: 2.1;
					margin: 10px 0 0 2px;
				}
			}
			.node-detail{
				a{
					position: absolute;
					top: 50%;
					transform:translateY(-50%);
					right: 5px;
					display: flex;
					flex-direction:row;
					align-items:center;
					color: white;
					font-family: Verdana;
					font-size: 16px;
					font-weight: 400;
					width: 175px;
					height: 52px;
					background-color: #da2d29;
					border-radius: 26px;
					border: 0;
					outline: 0;
					box-shadow: none;
					text-align: left;
					padding: 0 0 0 30px;
					margin: 0 0 0 0;
					background-image: url(../images/more-arrows-hover.png);
					background-repeat: no-repeat;
					background-position: center right 30px;
					&:hover{
						text-decoration: none;
					}
					@include respond-to('tablet'){
						right: auto;
						left: 50%;
						transform:translateX(-50%);
						top: auto;
						bottom: 10px;
					}
					@include respond-to('mobile'){
						right: auto;
						left: 50%;
						transform:translateX(-50%);
						top: auto;
						bottom: 10px;
					}
				}
			}
			&:hover{
				.cr-body{
					background-color: $navyblue;
					.node-title{
						color: white;
					}
					.teacher-name{

					}
				}
			}
		}
	}

	.pane-v-our-teachers-panel-pane-1{
		background-color: #f9f9f9;
		padding: 114px 0 112px 0;
		margin-top: 91px;
		@include respond-to('mobile') {
			padding: 60px 0;
		}
	}

	.view-v-our-teachers{
		margin-top: 51px;
		padding: 0 129px;
		@include respond-to('mobile'){
			padding: 0;
		}
		.view-content{
			.owl-carousel{

				.owl-wrapper-outer{
					.owl-wrapper{
						.owl-item{
							text-align: center;
							.views-field-field-image{
								img{
									border-radius: 100%;
								}
							}
							.views-field-field-mot-fio{
								color: $siniy;
								font-family: Verdana;
								font-size: 18px;
								font-weight: 700;
								line-height: 1.5;
								margin: 33px auto 0 auto;
								width: 271px;
								a{
									color: $siniy;
									font-family: Verdana;
									font-size: 18px;
									font-weight: 700;
									line-height: 1.5;
									&:hover{
										text-decoration: none;
									}
								}
							}
							.views-field-body{
								width: 287px;
								color: #999;
								font-family: Verdana;
								font-size: 14px;
								font-weight: 400;
								line-height: 1.94;
								margin: 27px auto 0 auto;
							}
						}
					}
				}

				.owl-controls{
					margin: 0 0 0 0;
					.owl-buttons{
						font-size: 0;
						.owl-prev{
							margin: 0 0px 0 0px;
							padding: 0;
							font-size: 0;
							text-indent: -9999px;
							width: 52px;
							height: 52px;
							border-radius: 100%;
							background-color: $navyblue;
							opacity: 1;
							background-image: url(../images/more-arrows-hover.png);
							background-repeat: no-repeat;
							background-position: center;
							transform: rotate(180deg);
							position: absolute;
							top: 16%;
							left: -68px;
							@include respond-to('mobile'){
								left: 10px
							}
							&:hover{
								background-color: #da2d29;
							}
						}
						.owl-next{
							margin: 0 0 0 0px;
						    padding: 0;
						    font-size: 0;
						    text-indent: -9999px;
						    width: 52px;
						    height: 52px;
						    border-radius: 100%;
						    background-color: $navyblue;
						    opacity: 1;
						    background-image: url(../images/more-arrows-hover.png);
						    background-repeat: no-repeat;
						    background-position: center;
						    position: absolute;
						    top: 16%;
						    right: -65px;
						    @include respond-to('mobile'){
						    	right: 10px;
						    }
						    &:hover{
						    	background-color: #da2d29;
						    }
						}
					}
				}

			}
		}
	}

	.pane-requisites{
		margin-top: 115px;
		@include respond-to('mobile') {
			margin-top: 60px;
		}
	}

	.requisites{
		text-align: center;
		margin-top: 41px;
		p{
			font-family: Verdana;
			font-size: 14px;
			line-height: 1.94;
			color: #999;
			font-weight: 400;
			margin-bottom: 0px;
			span{
				color: #da2d29;
				font-weight: 700;
			}
		}
	}

}

body.node-type-m-educational-center{

	.img-container{
		position: relative;
		.field-name-field-image{
			.field-item{
				background-repeat: no-repeat;
				background-position: top center;
				background-size: cover;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(7, 15, 47, 0.6);
				}
				img{
					opacity: 0;
				}
			}
		}
		.ic-container{
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction:row;
			align-items:center;
			min-height: 500px;
			padding-top: 25px;
			padding-bottom: 25px;
			@include respond-to('tablet'){
				flex-direction:column;
				justify-content:center;
			}
			@include respond-to('mobile'){
				flex-direction:column;
				justify-content:center;
			}
			.col{
				width: 50%;
				@include respond-to('tablet'){
					width: 100%;
				}
				@include respond-to('mobile'){
					width: 100%;
				}
			}
			.ic-title{
				color: white;
				font-family: $GothamProLight;
				font-size: 40px;
				line-height: 1;
				text-transform: uppercase;
				padding: 0 0 0 122px;
				@include respond-to('tablet'){
					padding: 0 0 0px 0;
					text-align: center;
				}
				@include respond-to('mobile'){
					padding: 0 0 0px 0;
					text-align: center;
					font-size: 28px;
				}
			}
			.ic-body{
				padding: 67px 126px 0 25px;
				@include respond-to('tablet'){
					padding: 30px 0 0px 0;
				}
				@include respond-to('mobile'){
					padding: 30px 0 0px 0;
				}
				.teacher-wrap{
					display: flex;
					flex-direction:row;
					@include respond-to('mobile'){
						flex-direction:column;
						align-items:center;
					}
					.teacher-photo{
						margin-right: 32px;
						margin-top: 10px;
						@include respond-to('mobile'){
							margin-right: 0px;
							margin-bottom: 15px;
						}
						img{
							display: block;
							border-radius: 100%;
						}
					}
					.teacher-resume{
						@include respond-to('mobile'){
							text-align: center;
						}
						.teacher-name{
							font-family: $GothamProBlack;
							font-size: 15px;
							line-height: 2.06;
							color: white;
							span{
								color: #da2d29;
								margin-right: 5px;
							}
						}
						.field-name-body{
							color: white;
							font-family: Verdana;
							font-size: 13px;
							font-weight: 400;
							line-height: 1.7;
							margin-top: 18px;
							p{
								&:not(:last-of-type){
									margin-bottom: 22px;
								}
							}
						}
					}
				}
			}
		}
	}

	.content{
		.field-name-body{
			margin-top: 82px;
			color: #999;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.94;
			padding: 0 130px 0 122px;
			@include respond-to('tablet'){
				padding: 0 30px;
			}
			@include respond-to('mobile'){
				padding: 0;
				margin-top: 35px;
			}
			p{
				margin-bottom: 28px;
			}
			.red-title{
				color: #da2d29;
				font-family: Verdana;
				font-size: 20px;
				font-weight: 700;
				line-height: 1.55;
				padding-top: 19px;
				margin-bottom: 20px;
			}
			ul{
				margin: 0 0 53px 0;
				li{
					line-height: 2.15;
					@include respond-to('tablet'){
						br{
							display: none;
						}
					}
					@include respond-to('mobile'){
						br{
							display: none;
						}
					}
					&:before{
						margin-right: 9px;
					}
				}
			}
			blockquote{
				background: url(../images/quote_icon_white.png) left 45px center no-repeat;
				background-color: #ebebeb;
				color: #7e7e7e;
				margin: 0 0 54px 0;
				width: 833px;
			    font-style: italic;
			    padding: 34px 65px 35px 167px;
			    @include respond-to('tablet'){
			    	width: 100%;
			    	padding: 25px 50px 25px 150px;
			    }
			    @include respond-to('mobile'){
			    	width: 100%;
			    	padding: 86px 15px 15px 15px;
			    	background-position: top 10px center;
			    }
			}
		}
	}

	.pane-webform-client-block-943{
		background-color: #f3f3f3;
		margin-top: 55px;
		padding-top: 86px;
		padding-bottom: 98px;
		.pane-content{
			@include make-container();
			padding-left: 123px;
			padding-right: 123px;
			@include respond-to('tablet'){
				padding-left: 0px;
				padding-right: 0px;
			}
			@include respond-to('mobile'){
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

}

form#webform-client-form-943{
	padding: 0;

	.form-item{
		margin-top: 0px;
		margin-bottom: 20px;
		label{
			color: $siniy;
			font-family: Verdana;
			font-size: 15px;
			font-weight: 700;
			line-height: 1.8;
			.form-required{
				display: none;
			}
		}
	}

	.webform-component--ostavit-zayavku{
		text-align: center;
		font-size: 0;
		margin-bottom: 50px;
		> div{
			display: inline-block;
			border-bottom: 2px solid #da2d29;
			padding: 0 7px;
		}
		color: $navyblue;
		font-size: 21px;
		line-height: 1.48;
		text-transform: uppercase;
		font-family: $GothamProLight;
		span{
			font-family: $GothamProBlack;
			color: $siniy;
		}
	}

	.webform-component-fieldset{
		margin: 0 0 35px 0;
		padding: 0 0 0 0;
		border: 0;
		background: none;
		border-radius: 0;
		box-shadow: none;
		.fieldset-wrapper{
			padding: 0;
		}
		legend.panel-heading{
			float: none;
			line-height: 1;
			border:0;
			background: none;
			margin: 0 0 11px 0;
			padding: 0 0 0 0;
			.panel-title{
				color: $siniy;
				font-family: Verdana;
				font-size: 15px;
				font-weight: 700;
				line-height: 2.06;
				text-align: center;
			}
		}
	}

	.webform-component-radios{
		text-align: center;
		> label{
			color: $siniy;
			font-family: Verdana;
			font-size: 15px;
			font-weight: 700;
			line-height: 1.8;
		}
	}

	.webform-component-checkboxes{
		text-align: center;
		> label{
			color: $siniy;
			font-family: Verdana;
			font-size: 15px;
			font-weight: 700;
			line-height: 1.8;
		}
	}

	.form-type-radio{
		> label{
			color: grey;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.94;
		}
		input[type="radio"] {
		  vertical-align: top;
		  width: 17px;
		  height: 17px;
		  margin: 0 3px 0 0;
		}
		input[type="radio"] + label {
		  cursor: pointer;
		}
		input[type="radio"]:not(checked) {
		  position: absolute;
		  opacity: 0;
		}
		input[type="radio"]:not(checked) + label {
		  position: relative;
		  padding: 0 0 0 31px;
		}
		input[type="radio"]:not(checked) + label:before {
		  content: '';
		  position: absolute;
		  top: 4px;
		  left: 0;
		  border-radius: 100%;
		  width: 22px;
		  height: 22px;
		  background-color: white;
		  box-shadow: inset 0 1px 0 #e5e5e5;
		}
		input[type="radio"]:not(checked) + label:after {
			content: '';
			position: absolute;
			top: 12px;
			left: 7px;
			width: 8px;
			height: 8px;
			background-color: #da2d29;
			border-radius: 100%;
			opacity: 0;
		}
		input[type="radio"]:checked + label:after {
		  opacity: 1;
		}

	}

	.form-type-checkbox{
		> label{
			color: grey;
			font-family: Verdana;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.94;
		}
		input[type="checkbox"] {
		  vertical-align: top;
		  width: 17px;
		  height: 17px;
		  margin: 0 3px 0 0;
		}
		input[type="checkbox"] + label {
		  cursor: pointer;
		}
		input[type="checkbox"]:not(checked) {
		  position: absolute;
		  opacity: 0;
		}
		input[type="checkbox"]:not(checked) + label {
		  position: relative;
		  padding: 0 0 0 31px;
		}
		input[type="checkbox"]:not(checked) + label:before {
		  content: '';
		  position: absolute;
		  top: 0px;
		  left: 0;
		  border-radius: 100%;
		  width: 22px;
		  height: 22px;
		  background-color: white;
		  box-shadow: inset 0 1px 0 #e5e5e5;
		}
		input[type="checkbox"]:not(checked) + label:after {
			content: '';
			position: absolute;
			top: 7px;
			left: 7px;
			width: 8px;
			height: 8px;
			background-color: #da2d29;
			border-radius: 100%;
			opacity: 0;
		}
		input[type="checkbox"]:checked + label:after {
		  opacity: 1;
		}

	}

	input[type="text"],
	input[type="email"]{
	    border: 0;
	    outline: 0;
	    padding: 0 0 0 39px;
	    width: 100%;
		height: 50px;
		background-color: white;
		border-radius: 25px;
		box-shadow: inset 0 1px 0 #e5e5e5;
	    color: grey;
	    font-family: Verdana;
	    font-size: 14px;
	    font-weight: 400;
	    @include placeholder(){
	    	color: grey;
	    	font-family: Verdana;
	    	font-size: 14px;
	    	font-weight: 400;
	    }
	}

	.webform-component--blagodarim{
		color: grey;
		font-family: Verdana;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.94;
		text-align: center;
		text-transform: none;
		margin-top: -24px;
		padding: 0 20px;
		@include respond-to('mobile'){
			padding: 0px;
		}
	}

	.form-actions{
		margin: 0 0 0 0;
		float: none;
		text-align: center;
		input[type="submit"]{
			width: 265px;
			height: 52px;
			background-color: #da2d29;
			padding: 0 0 0 47px;
			background-position: center right 47px;
		}
	}

	.webform-component--dannye-organizacii{
		margin: 0 0 47px 0;
		.webform-component-radios{
			font-size: 0;
			margin-top: 2px;
			> label{
				margin-right: 29px;
				margin-bottom: 0px;
				display: inline-block;
			}
			> .form-radios{
				display: inline-block;
			}
			.form-type-radio{
				display: inline;
				&:not(:last-child){
					margin-right: 31px;
				}

			}
		}
	}

	.webform-component--wrapper-1,
	.webform-component--dannye-organizacii,
	.webform-component--kontaktnoe-lico-wrap{
		clear: both;
		.panel-body{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:space-between;
			@include respond-to('tablet'){
				justify-content:center;
			}
			@include respond-to('mobile'){
				justify-content:flex-start;
				flex-direction:column;
				align-items:center;
			}
			&:before,
			&:after{
				content: none;
			}
			.webform-component-email,
			.webform-component-textfield{
				width: 298px;
				@include respond-to('tablet'){
					margin-left: 23px;
					margin-right: 23px;
				}
				@include respond-to('mobile'){
				}
			}
			.webform-component-radios{
				width: 100%;
			}
		}
	}

	.webform-component--rekvizity-organizacii,
	.webform-component--rukovoditel-kompanii{
		width: 50%;
		float: left;
		margin-bottom: 49px;
		@include respond-to('mobile'){
			float: none;
			width: 100%;
		}
	}

	.webform-component--rekvizity-organizacii{
		padding-right: 26px;
		@include respond-to('mobile'){
			padding-right: 0px;
		}
	}

	.webform-component--rukovoditel-kompanii{
		padding-left: 26px;
		@include respond-to('mobile'){
			padding-left: 0px;
		}
		.webform-component-radios{
			margin-top: -8px;
			.form-type-radio{
				display: inline;
				&:not(:last-child){
					margin-right: 28px;
				}

			}
		}
	}

	.webform-component--obrabotka-dannyh{
		text-align: center;
		margin-top: -13px;
		margin-bottom: 42px;
		.form-type-checkbox{
			input[type="checkbox"]:not(checked) + label{
				padding: 0 0 0 36px;
			}
			input[type="checkbox"]:not(checked) + label:before{
				top: 4px;
				left: 0px;
			}
			input[type="checkbox"]:not(checked) + label:after{
				top: 12px;
				left: 7px;
			}
		}
	}

}