.page-question-and-answer,.page-clinics-ask{
	div{
		// border: 1px solid red;
	}
	.page-title-container{
		margin-top: 86px;
	}
	.pane-content{
		position: relative;
		.view-filters{
			position: absolute;
			top: -39px;
			right: 0;
			@include respond-to('tablet'){
				position: relative;
				top: 0;
				text-align: center;
				max-width: 250px;
				margin: 18px auto 0 auto;
				.views-exposed-widgets{
					margin: 0;
				}
				.views-exposed-widget{
					float: none;
					padding: 0;
				}
			}
			@include respond-to('mobile'){
				position: relative;
				top: 0;
				text-align: center;
				max-width: 250px;
				margin: 18px auto 0 auto;
				.views-exposed-widgets{
					margin: 0;
				}
				.views-exposed-widget{
					float: none;
					padding: 0;
				}
			}
		}
	}
	.view-header{
		@include respond-to('tablet'){
			text-align: center;
			margin-top: 40px;
		}
		@include respond-to('mobile'){
			text-align: center;
			margin-top: 40px;
		}
		a{
			@extend .btn, .btn-default;
		    padding: 2px 71px;
		    border-radius: 80px;
		    color: #696969;
		    font-family: Verdana;
		    font-size: 11px;
		    font-weight: 700;
		    line-height: 27px;
		    text-transform: uppercase;
		    position: absolute;
		    left: 12px;
		    top: -32px;
		    outline: 0;
			&:active, &:hover, &:focus {
			    outline: 0;
			    outline-offset: 0;
			}
			@include respond-to('tablet'){
				position: relative;
				top: 0;
				left: 0;
			}
			@include respond-to('mobile'){
				position: relative;
				top: 0;
				left: 0;
			}
			&:hover{
				background: #da2d29;
				border: 1px solid #da2d29;
				color: white;
			} 		     
		}
	}
	.view-content{		
		padding-top: 23px;
		font-family: Verdana;
		font-size: 14px;
		line-height: 27px;
		color: #999;
		font-weight: 400;
		margin-bottom: 60px;
		.views-row{
			border-bottom: 1px solid #d6d9e5;
			padding: 60px 0;

		}
		.field-name-field-question .field-label{
			float: left;
			color: $siniy;
			font-weight: 700;
		}
		.name{
			font-weight: 700;
			line-height: 27px;
			color: #da2d29;
			margin-top: 12px;
		}
		.field-name-field-answer{
			color: #999;
			line-height: 27px;
			padding: 40px 60px; 
		    margin-top: 52px;
    		border-radius: 27px;
    		background: #f3f3f3;
    		position: relative;
    		@include respond-to('mobile'){
    			padding: 40px 28px;
    		}
    		&:before{
    			content: "";
    			display: inline-block;
				width: 30px;
				height: 25px;
				position: absolute;
				left: 37px;
				top: -25px;
    			background: url(../images/rowupanswer.png) no-repeat;
		
    		}
		}
	}
}