.page-node-21 {
  .node {
    margin: 85px 0 0 0;
  }
  .field-name-body {
    color: #999;
    font-weight: 400;
    font-size: 16px;
    a {
      color: $siniy;
      font-weight: 700;
      text-decoration: none;
    }
  }
}