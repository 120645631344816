body.node-type-m-services{

  .img-container{
    position: relative;
    .field-name-field-background{
      .field-item{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        @include respond-to('tablet'){
          height: 100%;
        }
        @include respond-to('mobile'){
          height: 100%;
        }
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(7, 15, 47, 0.5);
        }
        img{
          opacity: 0;
        }
      }
    }
    .ic-container{
      min-height: 500px;
      position: relative;
      z-index: 10;
      text-align: center;
      padding-left: 193px;
      padding-right: 193px;
      @include respond-to('tablet'){
        padding-left: 0px;
        padding-right: 0px;
      }
      @include respond-to('mobile') {
        padding-left: 10px;
        padding-right: 10px;
      }
      .field-name-field-image{
        margin: 108px 0 0 0;
        @include respond-to('tablet'){
          margin: 68px 0 0 0;
        }
        @include respond-to('mobile'){
          margin: 68px 0 0 0;
        }
        .field-item{
          background-repeat: no-repeat;
          background-position: top center;
          img{
            opacity: 0;
          }
        }
      }
      .node-title{
        color: white;
        font-family: Verdana;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 21px 0 0 0;
        line-height: 1.25;
        @include respond-to('tablet'){
          font-size: 20px;
          margin: 36px 0 0 0;
        }
        @include respond-to('mobile'){
          font-size: 20px;
          margin: 39px 0 0 0;
          line-height: 1.21;
        }
      }
      .field-name-field-subtitle{
        color: white;
        font-family: Verdana;
        font-size: 15px;
        font-weight: 400;
        margin: 14px 0 0 0;
        line-height: 1.8;
        @include respond-to('tablet'){
          margin: 25px 0 0 0;
          line-height: 1.25;
        }
        @include respond-to('mobile'){
          margin: 25px 0 0 0;
          line-height: 1.25;
        }
      }
    }
  }

  .sub-category{
    background-color: $navyblue;
    min-height: 328px;
    padding: 70px 0 69px 0px;
    @include respond-to('tablet'){
      min-height: auto;
      padding: 60px 0 60px 0px;
    }
    @include respond-to('mobile'){
      min-height: auto;
      padding: 60px 0 60px 0px;
    }
    .sc-container{
      .field-name-field-ms-subservices{
        .field-items{
          display: flex;
          flex-direction:row;
          justify-content:center;
          @include respond-to('tablet'){
            flex-direction:column;
            justify-content:flex-start;
            align-items:center;
          }
          @include respond-to('mobile'){
            flex-direction:column;
            justify-content:flex-start;
            align-items:center;
          }
          .col{
            width: calc(100% / 3);
            position: relative;
            padding-top: 16px;
            padding-bottom: 14px;
            &:not(:last-child){
              //padding-right: 50px;
              margin-right: 50px;
              &:after{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: rgba(255,255,255,.2);
              }
            }
          }
          .field-item{
            margin-bottom: 1px;
            @include respond-to('tablet'){
              width: 100%;
              margin-bottom: 0px;
              &:first-child{
                padding: 20px 45px 18px 10px;
                border: 1px solid rgba(255,255,255,.5);
                border-radius: 10px;
                background: url('../images/select-dropdown.png') center right 10px no-repeat;
                cursor: pointer;
              }
              &:not(:first-child){
                display: none;
                padding: 8px 20px 8px 20px;
                border-left: 1px solid rgba(255,255,255,.5);
                border-right: 1px solid rgba(255,255,255,.5);
                position: relative;

              }
              &:nth-child(2){
                &:before{
                  content: '';
                  position: absolute;
                  top: -6px;
                  left: -1px;
                  width: 1px;
                  height: 6px;
                  background-color: rgba(255,255,255,.5);
                }
                &:after{
                  content: '';
                  position: absolute;
                  top: -6px;
                  right: -1px;
                  width: 1px;
                  height: 6px;
                  background-color: rgba(255,255,255,.5);
                }
              }
              &:last-child{
                border-bottom: 1px solid rgba(255,255,255,.5);
                border-radius: 0px 0px 10px 10px;
              }
            }
            @include respond-to('mobile'){
              width: 100%;
              margin-bottom: 0px;
              &:first-child{
                padding: 20px 45px 18px 10px;
                border: 1px solid rgba(255,255,255,.5);
                border-radius: 10px;
                background: url('../images/select-dropdown.png') center right 10px no-repeat;
                cursor: pointer;
              }
              &:not(:first-child){
                display: none;
                padding: 8px 20px 8px 20px;
                border-left: 1px solid rgba(255,255,255,.5);
                border-right: 1px solid rgba(255,255,255,.5);
                position: relative;
              }
              &:nth-child(2){
                &:before{
                  content: '';
                  position: absolute;
                  top: -6px;
                  left: -1px;
                  width: 1px;
                  height: 6px;
                  background-color: rgba(255,255,255,.5);
                }
                &:after{
                  content: '';
                  position: absolute;
                  top: -6px;
                  right: -1px;
                  width: 1px;
                  height: 6px;
                  background-color: rgba(255,255,255,.5);
                }
              }
              &:last-child{
                border-bottom: 1px solid rgba(255,255,255,.5);
                border-radius: 0px 0px 10px 10px;
              }
            }
            a{
              display: block;
              font-family: Verdana;
              font-size: 13px;
              font-weight: 400;
              text-transform: uppercase;
              color: white;
              padding: 0 0 0 12px;
              position: relative;
              line-height: 3;
              @include respond-to('tablet'){
                line-height: 1.2;
              }
              @include respond-to('mobile'){
                line-height: 1.2;
              }
              &:hover{
                outline: 0;
                text-decoration: underline;
                @include respond-to('desktop'){
                  font-weight: 700;
                }
              }
              &:before{
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: #da2d29;
                top: 17px;
                left: 0;
                @include respond-to('tablet'){
                  top: 5px;
                }
                @include respond-to('mobile'){
                  top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .paragraphs-item-pb-text-area{
    &.use-padding{
      padding: 89px 0 80px 0;
      @include respond-to('tablet'){
        padding: 50px 0 81px 0;
      }
      @include respond-to('mobile'){
        padding: 50px 0 81px 0;
      }
    }
    >.content{

    }
  }

  .our-doc-insert{
    padding: 84px 0 84px 0;
    .view-content{
      padding: 34px 0 0 0;
      .views-row{
        margin-bottom: 0;
        @include respond-to('tablet'){
          width: 100%;
        }
        @include respond-to('mobile'){
          width: 100%;
        }
      }
      .node-doctor{
        margin-bottom: 0px;
        .field-name-field-doctor-photo{
          .field-item{
            background: none!important;
          }
        }
      }
    }
  }

}

.paragraphs-item-pb-video-const{
  > .content{
    position: relative;
  }
  .v-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .field-name-field-background{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(7, 15, 47, 0.5);
    }
  }
  .field-name-field-image{
    margin: 79px 0 0 0;
    @include respond-to('tablet'){
      margin: 63px 0 0 0;
    }
    @include respond-to('mobile'){
      margin: 63px 0 0 0;
    }
    .field-item{
      background-repeat: no-repeat;
      background-position: top center;
      img{
        opacity: 0;
      }
    }
  }
  .field-name-field-pbvc-title{
    color: white;
    font-family: Verdana;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    margin: 26px 0 0 0;
  }
  .field-name-field-pbvc-body{
    color: white;
    font-family: Verdana;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 7px 0 0 0;
    padding: 0 91px;
    line-height: 1.25;
    @include respond-to('tablet'){
      font-size: 20px;
      padding: 0;
      line-height: 1.2;
      margin: 17px 0 0 0;
    }
    @include respond-to('mobile'){
      font-size: 20px;
      padding: 0;
      line-height: 1.2;
      margin: 17px 0 0 0;
    }
  }
  .video-link{
    margin: 22px 0 0 0;
    text-align: center;
    a{
      display: block;
    }
  }
  .field-name-field-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    .youtube-container--responsive{
      position: static;
    }
  }
}

.paragraphs-item-pb-cost-insert{
  background-color: #f9f9f9;
  padding: 82px 0 72px 0;
  > .content{
    @include make-container();
    @include respond-to('mobile'){
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .field-name-field-title{
    font-size: 0;
    text-align: center;
    margin-bottom: 48px;
    .field-item {
      display: inline-block;
      color: $navyblue;
      font-family: $GothamProLight;
      font-size: 21px;
      text-transform: uppercase;
      padding: 0 30px 4px 30px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #da2d29;
      }
      span{
        font-family: $GothamProBlack;
      }
    }
  }
  .field-name-field-codes{
    .view-views-field-formatter-for-entity-paragraphs-item{
      .view-content{
        @include respond-to('tablet'){
          text-align: left;
        }
        @include respond-to('mobile'){
          text-align: left;
        }
        .views-row{
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid rgba(218,45,41,0.2);
          padding-bottom: 13px;
          margin-bottom: 18px;
          .views-field-field-pbc-name-serv{
            color: dimgrey;
              font-family: Verdana;
              font-size: 14px;
              font-weight: 400;
              margin-right: 10px;
          }
          .views-field-field-pbc-price{
            color: #da2d29;
            font-family: Verdana;
            font-size: 14px;
            font-weight: 700;
            margin-left: auto;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.paragraphs-item-pb-step-heal-ins{
  padding: 84px 0 90px 0;
  > .content{
    @include make-container();
  }
  .field-name-field-title{
    font-size: 0;
    text-align: center;
    margin-bottom: 41px;
    @include respond-to('tablet'){
      margin-bottom: 29px;
    }
    @include respond-to('mobile'){
      margin-bottom: 29px;
    }
    .field-item {
      display: inline-block;
      color: $navyblue;
      font-family: $GothamProLight;
      font-size: 21px;
      text-transform: uppercase;
      padding: 0px 13px 4px 13px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #da2d29;
      }
      span{
        font-family: $GothamProBlack;
      }
    }
  }
  .paragraphs-items-field-step-ins{
    .field-name-field-step-ins{
      > .field-items{
        @include respond-to('desktop'){
          display: flex;
          flex-direction:row;
          justify-content:space-between;
        }
        > .field-item{
          width: 268px;
          height: 268px;
          border-radius: 100%;
          background-color: $navyblue;
          &:hover{
            background-color: #da2d29;
          }

        }
      }
    }

    .owl-carousel{
      .owl-wrapper{
        > .owl-item{
          > .field-item {
            width: 240px;
            height: 240px;
            border-radius: 100%;
            background-color: $navyblue;
            margin-left: auto;
            margin-right: auto;
            &:hover{
              background-color: #da2d29;
            }
          }
        }
      }
    }

    .owl-nav,
    .owl-controls{
      margin: 0;
      .owl-prev{
        width: 14px;
        height: 25px;
        margin: 0;
        padding: 0;
        background: url('../images/left-red.png') no-repeat;
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        left: 10px;
        font-size: 0;
        text-indent: -9999px;
        margin: 0;
        padding: 0;
        background-color: transparent;
        opacity: 1;
        border-radius: 0;
        &:hover{
          background-color: transparent;
        }
      }
      .owl-next{
        width: 14px;
        height: 25px;
        margin: 0;
        padding: 0;
        background: url('../images/right-red.png') no-repeat;
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        right: 10px;
        font-size: 0;
        text-indent: -9999px;
        margin: 0;
        padding: 0;
        background-color: transparent;
        opacity: 1;
        border-radius: 0;
        &:hover{
          background-color: transparent;
        }
      }
    }

  }

  .paragraphs-item-pb-step-heal{
    > .content{
      display: flex;
      flex-direction:column;
      align-items:center;
    }
    .field-name-field-pbsh-step{
      color: white;
      font-family: Verdana;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 39px 0 0 0;
      @include respond-to('tablet'){
        margin: 34px 0 0 0;
      }
      @include respond-to('mobile'){
        margin: 34px 0 0 0;
      }
    }
    .field-name-field-text-long{
      color: white;
      font-family: Verdana;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.74;
      margin: 18px 0 0 0;
        padding: 0 17px;
        text-align: center;
        @include respond-to('tablet'){
          font-size: 13px;
          margin: 8px 0 0 0;
        }
        @include respond-to('mobile'){
          font-size: 13px;
          margin: 8px 0 0 0;
        }
    }
  }

}

.paragraphs-item-pb-reviews{
  background-color: #f9f9f9;
  padding: 84px 0 90px 0;
  > .content{
    @include make-container();
    @include respond-to('mobile') {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .view-v-reviews{
    .view-header{
      font-size: 0;
      text-align: center;
      margin-bottom: 41px;
      .view-title {
        display: inline-block;
        color: $navyblue;
        font-family: $GothamProLight;
        font-size: 21px;
        text-transform: uppercase;
        padding: 0px 13px 4px 13px;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #da2d29;
        }
        span{
          font-family: $GothamProBlack;
        }
      }
    }
    .view-content{
      .owl-carousel{
        .owl-wrapper-outer{
          .owl-item{
            .views-field-body{
              color: #999;
              font-family: Verdana;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.94;
              text-align: center;
            }
            .views-field-title{
              text-align: center;
              margin-top: 14px;
              a{
                color: #da2d29;
                font-family: Verdana;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.94;
              }
            }
          }
        }
        .owl-controls{
          margin: 43px 0 0 0;
          .owl-buttons{
            font-size: 0;
            .owl-prev{
              margin: 0 10px 0 0;
              padding: 0;
              font-size: 0;
              text-indent: -9999px;
              width: 52px;
              height: 52px;
              border-radius: 100%;
              background-color: $navyblue;
              opacity: 1;
              background-image: url('../images/more-arrows-hover.png');
              background-repeat: no-repeat;
              background-position: center;
              transform:rotate(180deg);
              &:hover{
                background-color: #da2d29;
              }
            }
            .owl-next{
              margin: 0 0 0 10px;
              padding: 0;
              font-size: 0;
              text-indent: -9999px;
              width: 52px;
              height: 52px;
              border-radius: 100%;
              background-color: $navyblue;
              opacity: 1;
              background-image: url('../images/more-arrows-hover.png');
              background-repeat: no-repeat;
              background-position: center;
              &:hover{
                background-color: #da2d29;
              }
            }
          }
        }
      }
    }
  }
}

.paragraphs-item-pb-result-heal-ins{
  padding: 83px 0 40px 0;
  >.content{
    @include make-container();
  }
  .field-name-field-title{
    font-size: 0;
    text-align: center;
    margin-bottom: 41px;
    .field-item {
      display: inline-block;
      color: $navyblue;
      font-family: $GothamProLight;
      font-size: 21px;
      text-transform: uppercase;
      padding: 0px 13px 4px 13px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #da2d29;
      }
      span{
        font-family: $GothamProBlack;
      }
    }
  }

  .field-name-field-result{
    .owl-carousel{
      .owl-wrapper-outer{
        .owl-item{
          .paragraphs-item-pb-result-heal{
            > .content{
              display: flex;
              flex-direction:row;
              justify-content:center;
              @include respond-to('tablet'){
                flex-direction:column;
                justify-content:flex-start;
                align-items:center;
              }
              @include respond-to('mobile'){
                flex-direction:column;
                justify-content:flex-start;
                align-items:center;
              }
            }
            .field-name-field-image{
              margin-right: 11px;
              @include respond-to('tablet'){
                margin-right: 0px;
                margin-bottom: 5px;
              }
              @include respond-to('mobile'){
                margin-right: 0px;
                margin-bottom: 5px;
              }
              .field-item{
                background-repeat: no-repeat;
                background-position: top center;
                position: relative;
                @include respond-to('mobile'){
                  width: 320px;
                  height: 220px;
                  margin-left: auto;
                  margin-right: auto;
                  background-size: cover;
                }
                &:after{
                  content: 'До';
                  position: absolute;
                  width: 161px;
                  height: 46px;
                  background-color: #da2d29;
                  border-radius: 28px 28px 0px 0px;
                  bottom: 0;
                  left: 50%;
                  transform:translateX(-50%);
                  color: white;
                  font-family: Verdana;
                  font-size: 20px;
                  font-weight: 700;
                  text-transform: uppercase;
                  text-align: center;
                  padding-top: 9px;
                }
                img{
                  opacity: 0;
                  @include respond-to('mobile'){
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .field-name-field-img-after{
              margin-left: 11px;
              @include respond-to('tablet'){
                margin-left: 0px;
                margin-top: 4px;
              }
              @include respond-to('mobile'){
                margin-left: 0px;
                margin-top: 4px;
              }
              .field-item{
                background-repeat: no-repeat;
                background-position: top center;
                position: relative;
                @include respond-to('mobile'){
                  width: 320px;
                  height: 220px;
                  margin-left: auto;
                  margin-right: auto;
                  background-size: cover;
                }
                &:after{
                  content: 'После';
                  position: absolute;
                  width: 161px;
                  height: 46px;
                  background-color: $navyblue;
                  border-radius: 28px 28px 0px 0px;
                  bottom: 0;
                  left: 50%;
                  transform:translateX(-50%);
                  color: white;
                  font-family: Verdana;
                  font-size: 20px;
                  font-weight: 700;
                  text-transform: uppercase;
                  text-align: center;
                  padding-top: 9px;
                }
                img{
                  opacity: 0;
                  @include respond-to('mobile'){
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .owl-controls{
        margin: 0px 0 0 0;
        @include respond-to('tablet'){
          margin: 40px 0 0 0;
        }
        @include respond-to('mobile'){
          margin: 40px 0 0 0;
        }
        .owl-buttons{
          font-size: 0;
          .owl-prev{
            margin: 0 0px 0 0;
            padding: 0;
            font-size: 0;
            text-indent: -9999px;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $navyblue;
            opacity: 1;
            background-image: url('../images/more-arrows-hover.png');
            background-repeat: no-repeat;
            background-position: center;
            transform: translateY(-50%) rotate(180deg);
            position: absolute;
            top: 50%;
            left: 0;
            @include respond-to('tablet'){
              position: relative;
              top:0;
              transform: rotate(180deg);
              margin-right: 10px;
            }
            @include respond-to('mobile'){
              position: relative;
              top:0;
              transform: rotate(180deg);
              margin-right: 10px;
            }
            &:hover{
              background-color: #da2d29;
            }
          }
          .owl-next{
            margin: 0 0 0 0px;
            padding: 0;
            font-size: 0;
            text-indent: -9999px;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $navyblue;
            opacity: 1;
            background-image: url('../images/more-arrows-hover.png');
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            right: 0;
            @include respond-to('tablet'){
              position: relative;
              top:0;
              transform:none;
              margin-left: 10px;
            }
            @include respond-to('mobile'){
              position: relative;
              top:0;
              transform:none;
              margin-left: 10px;
            }
            &:hover{
              background-color: #da2d29;
            }
          }
        }
      }
    }
  }

}

.paragraphs-item-pb-text-area{
  //padding: 89px 0 80px 0;
  >.content{
    //@include make-container();
  }
  @include respond-to('tablet'){

  }
  @include respond-to('mobile'){

  }
  p{
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.94;
    color: #999;
    margin: 0 0 27px 0;
    &.this-img{
      &:first-of-type{
        margin: 0 0 84px 0;
        @include respond-to('tablet'){
          margin: 0 0 62px 0;
        }
        @include respond-to('mobile'){
          margin: 0 0 62px 0;
        }
      }
    }
    &:last-child{
      margin: 0 0 0 0;
    }
  }

  p + p.this-img{
    img{
      margin-right: 58px;
      @include respond-to('tablet'){
        margin-right: 20px;
      }
      @include respond-to('mobile'){
        margin-right: 0px;
        float: none!important;
      }
    }
  }

  img{
    @include respond-to('tablet'){
      max-width: 100%;
      height: auto;
    }
    @include respond-to('mobile'){
      max-width: 100%;
      height: auto;
    }
  }

  a{
    font-family: Verdana;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.94;
    color: $navyblue;
    text-decoration: underline;
  }
  .title{
    color: #da2d29;
    font-family: $GothamProBlack;
    font-size: 31px;
    line-height: 1;
    text-transform: uppercase;
    margin: 275px 0 0 0;
  }
  .body{
    color: #666;
    font-family: Verdana;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.54;
    text-transform: uppercase;
    margin: 15px 0 0 0;
  }
  .photo-title{
    color: $siniy;
    font-family: $GothamProBlack;
    font-size: 21px;
    line-height: 1;
    margin: 0 0 16px 0;
    padding: 29px 0 0 0;
    @include respond-to('tablet'){
      margin: 0 0 15px 0;
      padding: 35px 0 0 0;
      line-height: 1.5;
    }
    @include respond-to('mobile'){
      margin: 0 0 15px 0;
      padding: 35px 0 0 0;
      line-height: 1.5;
    }
  }
  .red-title{
    color: #da2d29;
    font-family: Verdana;
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0 29px 0;
    @include respond-to('tablet'){
      text-align: left;
      line-height: 1.54;
      margin: 0px 0 19px 0;
    }
    @include respond-to('mobile'){
      text-align: left;
      line-height: 1.54;
      margin: 0px 0 19px 0;
    }
  }
  ul{
    padding: 0;
    margin: 0;
    li{
      list-style: none;
      font-family: Verdana;
      font-size: 17px;
      font-weight: 400;
      color: #999;
      position: relative;
      padding-left: 15px;
      margin: 0 0 16px 0;
      @include respond-to('tablet'){
        text-align: left;
      }
      @include respond-to('mobile'){
        text-align: left;
      }
      &:before{
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #da2d29;
      }
    }
  }

  ol{
    > li{
      font-family: Verdana;
      font-size: 17px;
      font-weight: 400;
      color: #999;
      > ul{
        padding: 16px 0 0 0;
      }
    }
  }

  ul{
    > li{
      > ul{
        padding: 16px 0 0 0;
        > li{
          &:not(:last-child){
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }

}

.paragraphs-item-pb-our-adv-ins{
  margin-bottom: 77px;
  @include respond-to('tablet'){
    padding: 0 10px;
    margin-bottom: 66px;
  }
  @include respond-to('mobile'){
    padding: 0 10px;
    margin-bottom: 66px;
  }
  > .content{
    @include make-container();
  }
  .field-name-field-pboai-title{
    font-size: 0;
    text-align: center;
    .field-item {
      display: inline-block;
      color: $navyblue;
      font-family: $GothamProLight;
      font-size: 21px;
      text-transform: uppercase;
      padding: 0 26px 4px 26px;
      position: relative;
      @include respond-to('tablet'){
        padding: 0 14px 4px 14px;
      }
      @include respond-to('mobile'){
        padding: 0 14px 4px 14px;
      }
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #da2d29;
      }
      span{
        font-family: $GothamProBlack;
        color: $siniy;
      }
    }
  }
  .paragraphs-items-field-pboai-adv{
    margin: 41px 0 0 0;
    .field-name-field-pboai-adv{
      a {
        text-decoration: none;
      }
      > .field-items{
        @include respond-to('desktop'){
          display: flex;
          flex-direction:row;
          flex-wrap:wrap;
          justify-content:space-between;
        }
        .field-item{
          width: 225px;
          @include respond-to('tablet'){
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
          @include respond-to('mobile'){
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
          &:nth-child(2n+1){
            .paragraphs-item-pb-our-advantages{
              .field-name-field-pboa-title{
                color: #da2d29;
              }
              .field-name-field-pboa-body{
                color: #da2d29;
              }
            }
          }
          &:nth-child(2n+2){
            .paragraphs-item-pb-our-advantages{
              .field-name-field-pboa-title{
                color: $siniy;
              }
              .field-name-field-pboa-body{
                color: $navyblue;
              }
            }
          }
          .paragraphs-item-pb-our-advantages{
            .field-name-field-image{
              min-height: 70px;
              .field-item{
                background-repeat: no-repeat;
                @include respond-to('tablet'){
                  background-position: top center;
                }
                @include respond-to('mobile'){
                  background-position: top center;
                }
                img{
                  opacity: 0;
                }
              }
            }
            .field-name-field-pboa-title{
              font-family: $GothamProBlack;
              font-size: 17px;
              text-transform: uppercase;
              margin: 21px 0 0 0;
            }
            .field-name-field-pboa-body{
              font-family: Verdana;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.74;
              margin: 16px 0 0 0;
            }
          }
        }
        .owl-item{
          &:nth-child(2n+1){
            .paragraphs-item-pb-our-advantages{
              .field-name-field-pboa-title{
                color: #da2d29;
              }
              .field-name-field-pboa-body{
                color: #da2d29;
              }
            }
          }
          &:nth-child(2n+2){
            .paragraphs-item-pb-our-advantages{
              .field-name-field-pboa-title{
                color: $navyblue;
              }
              .field-name-field-pboa-body{
                color: $navyblue;
              }
            }
          }
        }
        .owl-controls,
        .owl-nav{
          .owl-buttons{
            font-size: 0;
          }
          .owl-prev{
            margin: 0;
            padding: 0;
            font-size: 0;
            text-indent: -9999px;
            width: 14px;
            height: 25px;
            border-radius: 100%;
            opacity: 1;
            background-image: url('../images/left-red.png');
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 21px;
            left: 0px;
            &:hover{
              background-color: transparent;
            }
          }
          .owl-next{
            margin: 0;
            padding: 0;
            font-size: 0;
            text-indent: -9999px;
            width: 14px;
            height: 25px;
            border-radius: 100%;
            opacity: 1;
            background-image: url('../images/right-red.png');
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 21px;
            right: 0;
            &:hover{
              background-color: transparent;
            }
          }

        }
      }
    }
  }
}

.block-webform{
  .block__title{
    display: none;
  }
}

.webform-client-form{
  padding: 136px 0 0 69px;
}

.paragraphs-item-pb-double-blk{
  position: relative;
  > .content{
    @include clearfix();
  }
  .field-name-field-image{
    width: 50%;
    float: left;
    .field-item{
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      /*position: absolute;
      top: 0;
      left:0;*/
      width: 100%;
      img{
        opacity: 0;
      }
    }
  }
  .field-name-field-right-back{
    width: 50%;
    float: left;
    .field-item{
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      /*position: absolute;
      top: 0;
      right:0;*/
      width: 100%;
      img{
        opacity: 0;
      }
    }
  }

  .p-container{
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    .paragraphs-items-field-left-side{
      width: 50%;
      float: left;
      .paragraphs-item-pb-text-area{
        padding: 89px 0 80px 0;
        > .content{
          width: 100%;
          margin: 0;
        }
      }
    }
    .paragraphs-items-field-right-side{
      height: 100%;
      float: left;
      position: relative;
      width: 50%;
      .webform-confirmation-container {
        left: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }

}

@include respond-to('tablet'){
  .node-type-m-services{
    .paragraphs-item-pb-double-blk{
      .field-name-field-image{
        position: absolute;
        top: 0px;
        left: 50%;
        transform:translateX(-50%);
        width: 320px;
        height: 400px;
        z-index: 2;
        img{
          width: 320px;
          height: 400px;
        }
      }
      .field-name-field-right-back{
        width: 100%;
        float: none;
        .field-item{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 3;
        }
      }
      .p-container{
        position: relative;
        .paragraphs-items-field-left-side{
          width: 100%;
          float: none;
          height: 400px;
          margin-bottom: 20px;
          padding-top: 60px;
            .paragraphs-item-pb-text-area{
              padding: 0;
              .title{
                font-size: 18px;
                margin: 0;
                text-align: center;
                line-height: 1.24;
              }
              .body{
                text-align: center;
                margin: 23px 0 0 0;
              }
            }
        }
        .paragraphs-items-field-right-side{
          width: 100%;
          float: none;
        }
      }
    }
    .webform-client-form{
      padding: 136px 132px 112px 28px;
    }

  }
}


@include respond-to('mobile'){
  .node-type-m-services{
    .paragraphs-item-pb-double-blk{
      .field-name-field-image{
        display: none;
      }
      .field-name-field-right-back{
        width: 100%;
        float: none;
        .field-item{
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          z-index: 2;
        }
      }
      .p-container{
        position: relative;
        .paragraphs-items-field-left-side{
          display: none;
        }
        .paragraphs-items-field-right-side{
          width: 100%;
          float: none;
        }
      }
    }
    .webform-client-form{
      padding: 87px 8px 90px 8px;
    }

  }
}


.paragraphs-item-pb-gallery{
  >.content{

  }
  .paragraphs-items-field-pbg-sets{
    .field-name-field-pbg-sets{
      > .field-items{
        @include clearfix();
        .w25{
          width: 25%;
          @include respond-to('tablet'){
            width: 50%;
          }
        }
        .w50{
          width: 50%;
          @include respond-to('tablet'){
            width: 100%;
          }
        }
        .w100{
          width: 100%;
        }
        > .entity-paragraphs-item {
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            @include respond-to('tablet') {
              display: none;
            }
          }
        }
      }
      .paragraphs-item-image-image-style{
        float: left;
        @include respond-to('mobile'){
          float: none;
          text-align: center;
        }
        &.w25,
        &.w50,
        &.w100{
          @include respond-to('mobile'){
            width: initial;
          }
        }
        > .content{

        }
        a{
          display: block;
          background-repeat: no-repeat;
          background-position: top center;
          background-size: cover;
          position: relative;
          @include respond-to('mobile'){
            display: inline-block;
          }
          &:before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            background-image: url('../images/search.png');
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition-timing-function: ease-out;
            transform: scaleY(0);
            transform-origin: 50%;
            transition-duration: 0.3s;
            transition-property: transform;
            z-index: 2;
          }
          &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            z-index: 1;
          }
          &:hover{
            &:before{
              transform: scaleY(1);
              opacity: 1;
            }
          }
          img{
            opacity: 0;
            width: 100%;
            @include respond-to('mobile'){
              width: 320px;
              height: 320px;
            }
          }
        }
      }
      .paragraphs-item-pb-formatted-txt-blk{
        background-color: #da2d29;
        float: left;
        overflow-y: auto;
        padding: 4.5% 0% 1% 0%;
        @include respond-to('mobile'){
          width: initial;
          height: initial!important;
          float: none;
          padding: 89px 10px 36px 10px;
          background-color: transparent;
          text-align: center;
        }
        > .content{
          width: 78%;
          margin: 0 auto;
          @include respond-to('mobile'){
            width: 100%;
          }
        }
        .field-name-field-text-long{
          .title{
            color: white;
            font-family: $GothamProBlack;
            font-size: 22px;
            line-height: 1.33;
            text-transform: uppercase;
            margin: 0 0 12% 0;
            @include respond-to('mobile'){
              font-size: 18px;
              color: #da2d29;
              line-height: 1.25;
              margin: 0 0 29px 0;
            }
          }
          .body{
            color: white;
            font-family: Verdana;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.67;
            @include respond-to('mobile'){
              color: #999;
              line-height: 1.84;
            }
          }
        }
      }
      .owl-nav,
      .owl-controls{
        margin: 0;
        .owl-prev{
          width: 14px;
          height: 25px;
          margin: 0;
          padding: 0;
          background: url('../images/left-white.png') no-repeat;
          position: absolute;
          top: 50%;
          transform:translateY(-50%);
          left: calc(((100% - 320px) / 2) + 9px);
          font-size: 0;
          text-indent: -9999px;
          margin: 0;
          padding: 0;
          background-color: transparent;
          opacity: 1;
          border-radius: 0;
          &:hover{
            background-color: transparent;
          }
        }
        .owl-next{
          width: 14px;
          height: 25px;
          margin: 0;
          padding: 0;
          background: url('../images/right-white.png') no-repeat;
          position: absolute;
          top: 50%;
          transform:translateY(-50%);
          right: calc(((100% - 320px) / 2) + 9px);
          font-size: 0;
          text-indent: -9999px;
          margin: 0;
          padding: 0;
          background-color: transparent;
          opacity: 1;
          border-radius: 0;
          &:hover{
            background-color: transparent;
          }
        }
      }
    }
  }
}