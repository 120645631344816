.view-akcii {
  position: relative;
  .view-filters {
    position: absolute;
    right: -23px;
    top: -38px;
    @include respond-to('tablet'){
      margin-top: 40px;
      padding-right: 0px;
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      right: 0;
      position: relative;
      .views-exposed-widgets{
        margin: 0;
      }
        .views-exposed-widget {
          float: right;
          padding: 0;
        }
    }
    @include respond-to('mobile'){
      margin-top: 40px;
      padding-right: 0px;
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      right: 0;
      position: relative;
      .views-exposed-widgets{
        margin: 0;
      }
        .views-exposed-widget {
          float: right;
          padding: 0;
        }
    }
  }
  .view-content{
    display: flex;
    flex-wrap: wrap;
    padding-top: 91px;
    .views-row {
      border-bottom: 1px solid #d6d9e5;
      min-height: 401px;
      width: calc(100% / 3);
      margin-bottom: 61px;
      @include respond-to('tablet'){
        width: 50%;
        &:nth-child(2n+2){
          .node-akcii-teaser{
            margin-left: auto;
          }
        }
      }
      @include respond-to('mobile'){
        width: 100%;
        .node-akcii-teaser{
          margin-left: auto;
          margin-right: auto;
        }
      }
      @include respond-to('desktop'){
        &:nth-child(3n+2){
          .node-akcii-teaser{
            margin: 0 auto;
          }
        }
        &:nth-child(3n+3){
          .node-akcii-teaser{
            margin-left: auto;
          }
        }
      }

      .node-akcii-teaser {
        width: 300px;
        .image-container {
          position: relative;
        }
        .title-container {
          margin: 24px 0 0px 0;
          font-family: Verdana;
          font-size: 14px;
          line-height: 22px;
          color: $siniy;
          font-weight: bold;
          text-transform: uppercase;
          height: 22px;
          overflow: hidden;
        }

        .expanding-formatter-trigger {
          float: right;
        }

        .field-name-body,.body{
          margin: 17px 0 0px 0;
          line-height: 1.94;
          text-align: left;
          font-family: Verdana;
          color: #999999;
          font-size: 14px;
          a {
            display: block;
            color: #da2d29;
            &:focus,
            &:hover{
              text-decoration: none;
              outline: 0;
            }
          }
        }
        .body {
          height: 108px;
          overflow: hidden;
        }
        .read_more {
          a {
            display: block;
            color: #da2d29;
            &:focus,
            &:hover{
              text-decoration: none;
              outline: 0;
            }
          }
        }
        .field-name-field-discount {
            display: flex;
          align-items: center;
            justify-content: center;
            position: absolute;
            top: 28px;
            left: 32px;
          background-color: #d93c37;
            border-radius: 50%;
            height: 94px;
            width: 94px;
            color: white;
            font-family: $GothamProBlack;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
        }
      }
    }
  }
}

body.node-type-akcii{
  .node-akcii-full {
    margin-top: 60px;
  }
  .left-col{
    float: left;
    width: 360px;
    @include respond-to('tablet') {
      float: none;
    }
    @include respond-to('mobile') {
      float: none;
      width: 100%;
    }
    .image-container{
      position: relative;
      .field-item {
        background-image: none !important;
      }
    }
    .field-name-field-discount {
      position: absolute;
      background-color: red;
      align-items: center;
          justify-content: center;
        background-color: #d93c37;
          border-radius: 50%;
          top: 30px;
          display: flex;
          color: #fff;
          font-weight: 900;
          height: 94px;
          left: 30px;
          width: 94px;
          .field-items {
            color: #ffffff;
            font-size: 30px;
            text-align: center;
            line-height: 24px;
          }
    }
  }
  .right-col{
    float: left;
    width: calc(100% - 360px);
    @include respond-to('tablet') {
      float: none;
      width: 100%;
    }
    @include respond-to('mobile') {
      float: none;
      width: 100%;
    }
    .title-container {
      text-transform: uppercase;
      color: $siniy;
      font-weight: bold;
      font-family: Verdana;
      font-size: 14px;
      line-height: 25px;
    }

    .field-items {
      .field-item {
          color: darkgrey;
          padding: 30px 0;
      }
    }

    .field-name-body {
      img {
        max-width: 100%;
        @include respond-to('mobile') {
          height: auto;
        }
      }
      blockquote {
        p {
          margin: 0;
          color: #fff;
        }
      }
      p{
        color: #999999;
        margin-top: 30px;
        font-family: Verdana;
        font-size: 14px;
        line-height: 25px;
        @include respond-to('mobile') {
          margin-top: 0;
        }
      }
      h3{
        font-family: Verdana;
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
      }
      ul {
        color: #999999;
        margin-top: 30px;
        font-family: Verdana;
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
}

#fixed-webform {
  background-color: $navyblue;
  margin-top: 60px;
  width: 300px;
  &.fixed {
    margin: 0;
    position: fixed;
    top: 0;
    z-index: 200;
  }
  .alert {
    display: none;
  }
  .webform-client-form {
    padding: 30px;
  }
  .webform-component--zapishites {
    color: #fff;
    font-family: $GothamProRegular;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    .span {
      color: #da2d29;
      font-family: $GothamProBlack;
      font-weight: 900;
    }
  }
  .form-item {
    margin-bottom: 15px;
  }
  .webform-component--vyberite-filial {
    width: 100%;
  }
  .webform-component--zhelaemaya-data-i-vremya- {
    width: 100%;
  }
  .webform-component--kommentariy {
    width: 100%;
  }
  .form-actions {
    margin: 20px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    input[type=submit] {
      display: inline-block;
    }
  }
}