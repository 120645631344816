body.page-doctors{
	.page-title-container{
		@include respond-to('mobile'){
			margin-top: 79px;
		}
		.page-title{
			padding: 0 87px;
		}
	}	
	.view-doctors {
		.view-content{
			.views-row{
				@include respond-to('tablet'){
					width: 50%;
				}
				@include respond-to('mobile'){
					width: 100%;
					margin-bottom: 59px;
				    padding-bottom: 60px;
				    &:not(:last-child){
				    	border-bottom: 1px solid rgba(50,66,127,.3);
				    }
				}
			}
		}
	}	
}

.view-doctors { 
	text-align: center;
	position: relative;
	padding: 91px 0 0 0;
	@include respond-to('tablet'){
		padding: 40px 0 0 0;
	}
	@include respond-to('mobile'){
		padding: 40px 0 0 0;
	}
	.view-filters {
		position: absolute;
		right: 31px;
		top: -39px;
		@include respond-to('tablet'){
			position: relative;
			right: 0px;
			top: 0;
			max-width: 250px;
			margin: 0 auto 70px auto;
			.views-exposed-widgets{
				margin: 0;
			}
			.views-exposed-widget{
				float: none;
				padding: 0;
			}
		}
		@include respond-to('mobile'){
			position: relative;
			right: 0px;
			top: 0;
			max-width: 250px;
			margin: 0 auto 67px auto;
			.views-exposed-widgets{
				margin: 0;
			}
			.views-exposed-widget{
				float: none;
				padding: 0;
			}
		}
	}

	.view-content {
		display: flex;
		flex-direction:row;
		flex-wrap: wrap;
		@include respond-to('tablet'){
			justify-content:center;
		}
		@include respond-to('mobile'){
		}
		.views-row {
			width: calc(100%/3);
			margin-bottom: 90px;
			@include respond-to('desktop'){
				&:nth-child(3n+2){
					.node-doctor-teaser{
						margin: 0 auto;
					}	
				}
				&:nth-child(3n+3){
					.node-doctor-teaser{
						margin-left: auto;
					}	
				}
			}
		}
	}

	.node-doctor {	
		> .content{
			width: 300px;
			margin: 0 auto;
		}
		.field-name-field-doctor-photo {
			margin-bottom: 34px;
			.field-item{
				@include respond-to('tablet'){
					background: none!important;
				}
				@include respond-to('mobile'){
					background: none!important;
				}
			}
			img{
				border-radius: 80px;
				&:hover{
					box-shadow: 0 22px 22px rgba(0, 0, 0, 0.22);
				}	 
			}
		}

		.field-name-field-doctor-text-preview {
			color: #999999;
			font-family: Verdana;
		    font-size: 14px;
		    line-height: 27px;
		    margin-bottom: 35px;
		    min-height: 54px;
		}
		.title {
		    margin-bottom: 27px;
		    min-height: 54px;
		    a{
	    		color: #696969;
	    		font-family: Verdana;
	    	    font-size: 18px;
	    	    font-weight: 700;
	    	    line-height: 27px;
	    	    &:hover{
	    	    	text-decoration: none;
	    	    	color: #da2d29;
	    	    }
		    }
		}
		.links.inline{
			.node-readmore{
				a{
					@extend .btn, .btn-default;
					width: 175px;
					border-radius: 80px;
					color: #da2d29;
					border: 1px solid #f4c0be;
					font-weight: 400;
					font-family: Verdana;
				    font-size: 16px;
				    line-height: 27px;
				    background-image: url('../images/more-arrows.png');
				    background-repeat: no-repeat;
				    background-position: center right 30px;
				    padding: 11px 30px 12px 30px;
				    text-align: left;
				    &:hover {
				    	color: #ffffff;
		    			background-color: #da2d29;
				    	background-image: url('../images/more-arrows-hover.png');
				    }
				}
			}
		} 	 
	}
}

.form-control.jq-selectbox {
	width: 100%;
	.jq-selectbox__select {
		background:#2dc0f6;
		position: relative;
		text-align: left;
		padding-left: 29px;
		line-height: 32px;
		width: 250px;
		height: 32px;
		background-color: $navyblue;
		border-radius: 16px;
		.jq-selectbox__select-text {
			color: white;
			font-family: Verdana;
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			width: initial!important;
		}
	}
	.jq-selectbox__trigger {
		right: 26px;
		background-image: url(../images/dropdown-menu-hover.png);
	}
	.jq-selectbox__dropdown {
		height: 207px!important;
		padding: 27px 20px 25px 27px;
		background-color: rgba(50,66,127,.9);
		border-radius: 0px 0px 16px 16px;
	}
	ul {
		height: 160px;
		width: 100%;
	}
	li {
		color: white;
		font-family: Verdana;
		font-size: 13px;
		font-weight: 400;
		padding-left: 0px;
		padding-bottom: 11px;
		&:before {
			display: none;
		}
		&:hover { 
			font-weight: 700;
			text-decoration: underline;
		}
	}
}

.jspPane{
	padding: 0!important;
}
.jspVerticalBar {
	width: 5px;
	.jspTrack,.jspDrag {
		border-radius: 0px;
	}
	.jspTrack {
		background:url('../images/scroll_select_bg.jpg') top center repeat-y;
	}
	.jspDrag{
		background: #da2d29;
	}
}